@import '../../../../../styles/main.scss';
.osb-light-review-booking-component-container {
    width: 100%;
    background-color: $scard-light-grey;
    display: grid;
    grid-template-columns: 100%;
    padding: 4%;
    border-radius: 16px;
    @media only screen and (max-width: 948px) {
        padding: 6%;
    }

    .light-review-booking-back {
        grid-row-start: 1;
        grid-column-start: 1;
        display: flex;
        flex-direction: row;
        visibility: visible;
        line-height: 3rem;

        .osb-secondary-button {
            display: grid;
            white-space: nowrap;
            grid-template-columns: 50% 50%;
            margin-top: 15px;
            padding: 0;
        }
    }
    .light-review-booking-title {
        grid-column-start: 1;
        font-size: 32px;
        font-family: $f1-font-regular;
        line-height: 40px;
        padding-top: 20px;
        color: $breadcrumb-blue;
        text-transform: capitalize;
    }

    .light-request-booking-button {
        grid-row-start: 5;
        grid-column-start: 1;
        width: 100%;
        margin-top: 40px;
        display: grid;
        justify-content: left;

        @media only screen and (max-width: 948px) {
            display: grid;
            justify-content: center;
        }
        @include mobile {
            display: block;
        }
        button {
            font-size: 14px;
            line-height: 20px;
            font-family: $f1-font-bold;
            font-weight: 700;
            @include mobile {
                width: 100%;
                float: none !important;
                display: block;
            }
        }
    }
}
