@import '../../../../../../styles/main.scss';
.light-journey-servicesflow-page {
    background-color: $fds-color-white;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-column-gap: 2%;
    grid-template-columns: 65% 33%;

    @media only screen and (max-width: 1200px) {
        margin: 0 auto;
    }

    @include tablet-portrait {
        display: block;
        width: 100%;
        grid-template-columns: 100%;
        grid-column-gap: 0;
    }
    .service-card-list-container {
        margin-bottom: 30px;
        background-color: #ffffff;
        .osb-secondary-button {
            font-family: $f1-font-bold;
            font-size: 14px;
            line-height: 20;
            display: flex;
            @include desktop {
                padding: 4% 0 20px 8%;
            }
            ul {
                line-height: 20;
                display: flex;
                position: relative;
            }
            li {
                font-size: 14px;
                position: relative;
                line-height: 25px;
                .bttonIcon {
                    display: flex;
                    position: relative;
                }
            }
        }

        @include tablet-portrait {
            padding: 20px 23px;
        }

        .osb-secondary-button + .service-card-list-title {
            margin: 0 0 0 8%;
            @include mobile {
                margin: 30px 5px;
            }
        }

        .service-card-list-title {
            margin: 30px 0 0 8%;
            font-family: $f1-font-light;
            font-size: 34px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            color: #00095b;
            text-transform: uppercase;
            @include mobile {
                width: 100%;
                margin: 30px 0;
                font-size: 20px;
            }
        }
        .service-cards {
            @include desktop {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 197px;
                margin-left: 5%;
            }
            @include tablet {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 197px;
                margin-left: 5%;
            }
            @include mobile {
                margin-bottom: 56px;
            }
            .osb-card {
                @include desktop {
                    margin-top: 20px;
                    margin-bottom: 26px;
                }
                @include mobile {
                    width: 100%;
                    margin: 20px 0 50px;
                }
                &:last-child {
                    @include mobile {
                        border-bottom: 1px solid #ffffff;
                    }
                }
            }
        }
        .osb-loading {
            z-index: 10000;
        }
    }
}
