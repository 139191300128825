@import '../../../../../styles/main.scss';

.retrieve-access-code-container {
    display: flex;
    flex-direction: column;
    margin: 0 25% 0;
    line-height: 1.63;
    padding: 4% 0;
    @include mobile {
        margin: 0 5%;
    }
    .retrieve-access-code-header {
        font-size: 40px;
        color: #00095b;
        text-align: center;
        text-transform: capitalize;
        font-family: $f1-font-light;
        @media only screen and (max-width: 415px) and (min-width: 360px) {
            font-size: 20px;
        }
        @media only screen and (max-width: 320px) and (min-width: 280px) {
            font-size: 14px;
        }
        @media only screen and (max-width: 768px) and (min-width: 416px) {
            font-size: 24px;
        }
    }
    .retrieve-access-code-sub-header {
        width: 100%;
        font-family: $f1-font-regular;
        font-size: 14px;
        color: $fm-text-grey;
        margin: 30px 0 0 0;
        @include xs-mobile {
            font-size: 8px;
        }
        @include mobile {
            font-size: 10px;
        }
    }
    .email-address-link-container {
        margin: 0 10%;
        @include mobile {
            margin: 0;
        }
        .email-address-container {
            margin: 20px 0 0 0;
            width: 100%;
            @include mobile {
                width: 100%;
            }
            .rac-email-address {
                width: 100%;
                height: 40px;
                margin: 13px 0 0;
                padding: 12px 41px 12px 15px;
                border-radius: 7px;
                border: solid 1px #6e6e6e;
                background-color: #ffffff;
                font-size: 14px;
                color: #00095b;
                font-family: $f1-font-regular;
            }
            .rac-email-address-error {
                border: solid 1px #d62d14;
            }
            .email-address-label {
                display: flex;
                font-size: 14px;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
                .rac-email-error-icon {
                    width: 16px;
                    height: 16px;
                }
            }
            .rac-email-invalid {
                margin-top: 5px;
                font-size: 14px;
                color: #d62d14;
                @include xs-mobile {
                    font-size: 8px;
                }
                @include mobile {
                    font-size: 10px;
                }
            }
        }
        .remember-access-code-link {
            font-size: 14px;
            color: $fm-breadcrumb-blue;
            margin: 25px 0 0 0;
            text-decoration: underline;
            cursor: pointer;
            @include xs-mobile {
                font-size: 10px;
            }
            @include mobile {
                font-size: 12px;
            }
        }
    }
    .retrieve-access-code-button {
        margin: 30px auto 30px;
        text-align: center;
        @include mobile {
            margin: 30px 0 30px;
            .primary-button {
                width: 100%;
                display: block;
                .button-label {
                    font-size: 15px;
                }
            }
        }
    }
}
