@import './light-vouchers-ford.scss';

.brand-lincoln-content.voucher-popup-content,
.brand-lincolndemo-content.voucher-popup-content {
    background: rgb(255, 255, 255);
    display: grid;
    border: 1px solid #6e6e6e;
    opacity: 6.4;
    width: 50%;
    height: auto;
    padding: 20px 0;
    object-fit: contain;
    min-height: 30%;

    .osb-loading {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        z-index: 50;
        width: 130px;
        height: 50px;
        margin: auto;
    }

    .fds-activity-indicator {
        margin-top: 32px;
        &.full-height {
            height: 100vh;
        }
    }
    .fds-activity-indicator--circular:not(.fds--ie11) {
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        & .fds-activity-indicator__progress-wrapper {
            height: 81px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                content: '';
                display: block;
                border: 1px solid #6e6e6e;
                border-radius: 50%;
                width: 75px;
                height: 75px;
            }
        }

        & .fds-activity-indicator__progress-line {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .fds-activity-indicator__progress-line_svg {
            width: 81px;
            height: 81px;
            transform: rotate(-90deg);
        }

        & .fds-activity-indicator__progress-line_line--indeterminate {
            stroke-dasharray: 38, 162;
            stroke-dashoffset: 0;
            stroke-width: 2px;
            animation: fds-activity-indicator__animation--circular--indeterminate
                3s linear infinite;
            stroke-linecap: round;
            stroke: $brand-secondary;
        }

        @-webkit-keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }
    }

    .fds-activity-indicator__notification,
    .fds-activity-indicator__instructions {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .brand-lincoln,
    .brand-lincolndemo {
        .close-button-voucher {
            margin-right: 1%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            font-size: 210%;
            line-height: 10px;
            color: $brand-secondary;
            padding-left: 10px;
            font-family: $proxima-nova-regular;
        }
        .content-header-voucher {
            display: flex;
            justify-content: center;
            font-family: $proxima-nova-semi-bold;
            font-size: 20px;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: 3px;
            text-align: center;
            color: $brand-secondary;
            width: 100%;
            text-transform: uppercase;
        }
        .content-voucher-body {
            display: grid;
            grid-template-columns: 40%;
            justify-content: center;
            margin-top: 20px;

            .osb-error-icon {
                width: 20px;
                float: initial;
            }
            .content-voucher-input {
                grid-column-start: 1;
                grid-row-start: 1;
                width: 100%;
                height: 50px;
                border-radius: 3px;
                border: solid 1px #6e6e6e;
                font-family: $proxima-nova-regular;
                font-size: 16px;
                line-height: 1.63;
                letter-spacing: 1px;
                color: $brand-secondary;
                padding: 12px 15px;
            }
            .voucher-input-shadow {
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                    0 20px 20px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #6e6e6e;
                background-color: #ffffff;
                color: $brand-secondary;
                margin-bottom: 5px 0 24px 0;
            }
            .voucher-input-error {
                border: solid 1px $brand-light-error;
                background-color: #ffffff;
                box-shadow: none;
            }
            .voucher-invalid {
                width: auto;
                height: auto;
                margin-top: 5px;
                font-family: $proxima-nova-regular;
                font-size: 12px;
                line-height: 1.75;
                letter-spacing: 1px;
                color: $brand-light-error;
            }
            .content-vouchers-text-description {
                grid-column-start: 1;
                grid-row-start: 2;
                margin-top: 30px;
                font-family: $proxima-nova-regular;
                font-size: 12px;
                line-height: 1;
                letter-spacing: 1px;
                color: $brand-secondary;
            }
            .content-add-voucher-button {
                grid-column-start: 1;
                grid-row-start: 3;
                margin-top: 20px;
                display: flex;
                justify-content: center;
            }
        }
        .content-voucher-footer {
            display: grid;
            grid-template-columns: 50%;
            justify-content: center;
            margin-top: 20px;

            .content-voucher-applied-title {
                grid-column-start: 1;
                grid-row-start: 1;
                display: flex;
                flex-direction: row;

                .content-voucher-applied {
                    font-family: $proxima-nova-regular;
                    margin-left: 15px;
                    font-size: 16px;
                    line-height: 1.63;
                    letter-spacing: 2px;
                    color: $brand-secondary;
                    text-transform: uppercase;
                }
            }
            .content-voucher-applied-desc {
                grid-column-start: 1;
                grid-row-start: 2;
                height: auto;
                font-family: $proxima-nova-regular;
                font-size: 12px;
                line-height: 1.75;
                letter-spacing: 1px;
                color: $brand-secondary;
                margin-top: 20px;
            }
            .content-voucher-footer-button {
                grid-column-start: 1;
                grid-row-start: 3;
                height: auto;
                display: grid;
                margin-top: 20px;
                grid-template-columns: 50% 50%;

                .content-voucher-remove-link {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    font-family: $proxima-nova-regular;
                    font-size: 16px;
                    line-height: 1.63;
                    letter-spacing: 1px;
                    color: #4f4c43;
                    margin-top: 5%;
                    text-decoration: underline;
                    white-space: break-spaces;
                    cursor: pointer;
                }

                .conntent-voucher-confirm-button {
                    grid-column-start: 2;
                    grid-row-start: 1;
                    // display: grid;
                    justify-content: end;
                    margin-top: 5%;
                }
            }
        }
    }
}
@media only screen and (max-width: 948px) {
    .brand-lincoln-content.voucher-popup-content,
    .brand-lincolndemo-content.voucher-popup-content {
        opacity: 6.4;
        width: 100%;
        height: 100%;
        padding: 20px;
        .brand-lincoln,
        .brand-lincolndemo {
            .content-header-voucher {
                display: flex;
                justify-content: left;
                font-family: $proxima-nova-semi-bold;
                font-size: 20px;
                font-weight: 300;
                line-height: 1.5;
                letter-spacing: 3px;
                text-align: center;
                color: $brand-secondary;
                width: 100%;
                text-transform: uppercase;
                margin-top: 40px;
            }
            .content-voucher-body {
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                margin-top: 50px;
                .content-voucher-input {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    width: 100%;
                    height: 50px;
                    border-radius: 3px;
                    border: solid 1px #6e6e6e;
                    font-size: 16px;
                    line-height: 1.63;
                    letter-spacing: 1px;
                    color: $brand-secondary;
                }
                .content-vouchers-text-description {
                    grid-column-start: 1;
                    grid-row-start: 2;
                    margin-top: 60px;
                    font-size: 12px;
                    line-height: 1;
                    letter-spacing: 1px;
                    color: $brand-secondary;
                }
                .content-add-voucher-button {
                    grid-column-start: 1;
                    grid-row-start: 3;
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
            .content-voucher-footer {
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                margin-top: 20px;

                .content-voucher-applied-title {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    display: flex;
                    flex-direction: row;

                    .content-voucher-applied {
                        font-family: $proxima-nova-regular;
                        margin-left: 15px;
                        font-size: 16px;
                        line-height: 1.63;
                        letter-spacing: 2px;
                        color: $brand-secondary;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
                .content-voucher-applied-desc {
                    grid-column-start: 1;
                    grid-row-start: 2;
                    height: auto;
                    font-size: 12px;
                    line-height: 1.75;
                    letter-spacing: 1px;
                    color: $brand-secondary;
                }
                .content-voucher-footer-button {
                    grid-column-start: 1;
                    grid-row-start: 3;
                    height: auto;
                    display: grid;
                    margin-top: 20px;
                    grid-template-columns: 100%;

                    .content-voucher-remove-link {
                        grid-column-start: 1;
                        grid-row-start: 2;
                        font-family: $proxima-nova-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        letter-spacing: 1px;
                        color: #4f4c43;
                        margin-top: 5%;
                        text-decoration: underline;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                    }

                    .conntent-voucher-confirm-button {
                        grid-column-start: 1;
                        grid-row-start: 1;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
