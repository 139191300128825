@import './osb-banner-ford.scss';
.brand-lincoln {
    .osb-banner {
        height: 68px;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        @include mobile {
            height: 60px;
        }
        display: flex;
        z-index: 999 !important;
        .leftSide {
            margin: auto;
            text-transform: uppercase;
            height: 100%;
            display: table;
            padding-left: 70px;
            @include mobile {
                width: 100%;
                padding-left: 20px;
            }
            @include tablet {
                width: 40%;
            }
            @include desktop {
                width: 100%;
            }
            background-color: #1700f4;
            span {
                display: table-cell;
                vertical-align: middle;
                color: #fff;
                font-family: $proxima-nova-regular;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: 1.17px;
            }
        }
    }
}
