@import './booking-summary-accordion-ford.scss';

.brand-lincoln {
    .accordion-section {
        color: $dropdown-text;
        font-family: $proxima-nova-regular;

        .accordion-title {
            background-color: $white;
            font-family: $proxima-nova-regular;
            border: none;
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            outline: none;
            &:hover {
                cursor: pointer;
            }
            &.border-bottom {
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }
        }
        .panel {
            &.expanded {
                padding-bottom: 0;
            }
        }
        .header-color {
            color: $brand-secondary;
        }

        .osb-mobile-summary-section {
            .total-price {
                font-family: $proxima-nova-semi-bold;
                color: $brand-secondary;
                .discountedPrice {
                    color: $brand-border;
                }
            }
            .summary-button-section {
                .view-summary,
                .add-voucher,
                .light-remove-voucher-link {
                    color: #4f4c43;
                    font-family: $proxima-nova-semi-bold;
                }
            }
        }
    }

    .osb-mobile-booking-summary {
        color: $brand-secondary;
        background-color: #fff;
        .view-summary-accordion {
            .osb-light-journey-mobile-booking-summary {
                .osb-review-booking-container {
                    .vehicle-section {
                        .vehicle-title {
                            font-family: $proxima-nova-semi-bold;
                            color: $brand-secondary;
                        }
                        .vehicle-name {
                            font-family: $proxima-nova-semi-bold;
                            color: $brand-secondary;
                        }
                        .change-link {
                            color: #4f4c43;
                            font-family: $proxima-nova-regular;
                        }
                    }
                    .dealer-section {
                        .dealer-title {
                            font-family: $proxima-nova-semi-bold;
                            color: $brand-secondary;
                        }
                        .dealer-name {
                            font-family: $proxima-nova-regular;
                            color: $brand-secondary;
                        }
                        .dealer-address {
                            color: $brand-secondary;
                            font-family: $proxima-nova-regular;
                        }
                        .change-link {
                            color: #4f4c43;
                            font-family: $proxima-nova-regular;
                        }
                    }

                    .service-sub-title {
                        color: $brand-secondary;
                        font-family: $proxima-nova-semi-bold;
                    }
                    .osb-light-booking-summary-service-section {
                        .service-title {
                            font-family: $proxima-nova-semi-bold;
                            color: $brand-secondary;
                        }
                        .services-list {
                            .single-service {
                                .service-name {
                                    font-family: $proxima-nova-regular;
                                    color: $brand-secondary;
                                }
                                .light-recall-service-desc {
                                    font-family: $proxima-nova-regular;
                                    color: $brand-secondary;
                                }
                                .service-price {
                                    font-family: $proxima-nova-regular;
                                    color: $brand-secondary;
                                    .discountedPrice {
                                        color: $brand-border;
                                    }
                                }
                            }
                        }
                        .service-change-link {
                            color: #4f4c43;
                            font-family: $proxima-nova-regular;
                        }
                    }
                    .datetime-section {
                        .datetime-title {
                            font-family: $proxima-nova-semi-bold;
                            color: $brand-secondary;
                        }
                        .date-time {
                            font-family: $proxima-nova-regular;
                            color: $brand-secondary;
                        }
                        .change-link {
                            color: #4f4c43;
                            font-family: $proxima-nova-regular;
                        }
                    }
                }
                .osb-show-video-link {
                    font-family: $proxima-nova-regular;
                    color: #4f4c43;
                    .show-video-container {
                        .show-video-text {
                            color: $brand-secondary;
                        }
                    }
                }
            }
        }
    }
}
