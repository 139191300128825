@import './light-retrieve-booking-details-ford.scss';

.brand-lincoln {
    .osb-light-review-booking-container {
        .light-review-section-title {
            font-family: $proxima-nova-semi-bold;
            color: $brand-secondary;
        }
        .light-review-section-body {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .light-review-section-services-body {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;

            .light-service-group {
                .light-service-name {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                    .light-recall-service-desc {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                }
                .light-service-price {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;

                    .discountedPrice {
                        text-decoration: line-through;
                        color: #f26147;
                        font-family: $proxima-nova-regular;
                    }
                }
            }
        }
        .light-review-change-link {
            font-family: $proxima-nova-regular;
            color: #4f4c43;
        }

        .light-review-comments-section {
            .comment-container .comments-body .comments-remaining-char {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }

            .comment-container .comment-input textarea {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .comment-empty-error {
                border: solid 1px $brand-border;
                background-color: #ffffff;
            }
        }
        .light-review-voucher-section {
            .light-add-voucher {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }

            .light-review-voucher-title {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .light-voucher-description-section {
                .light-voucher-des {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .light-voucher-off {
                    font-family: $proxima-nova-regular;
                    color: #008200;
                }
            }
            .light-remove-voucher-link {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
        }
        .light-review-total-section {
            .light-total-body {
                .light-total-title {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                    .light-total-subtitle {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                }
                .light-total-price {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                    .discountedPrice {
                        color: #f26147;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 948px) {
        .osb-light-review-booking-container {
            .light-review-comments-section {
                .comment-container .comments-body {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
                .comment-container .comments-body .comments-remaining-char {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }

                .comment-container .comment-input textarea {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
            }
            .light-review-total-section {
                .light-total-body {
                    .light-total-price {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                        .discountedPrice {
                            color: #f26147;
                        }
                    }
                }
            }
        }
    }
}
