@import './light-retrieve-booking-ford.scss';

.brand-lincoln {
    .osb-light-retrieve-booking-container {
        .osb-retrieve-booking-view-title {
            font-family: $proxima-nova-regular;
            color: #000;
            letter-spacing: 1px;
        }
        .show-retrieve-access-code-msg {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .input-textbox-error-message {
            font-family: $proxima-nova-regular;
            color: #b00020;
        }
        .input-textbox {
            border: none;
            border-bottom: solid 1px #6e6e6e;
            background-color: #ecedee;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            &:hover {
                border-bottom: solid 2px #324047;
            }
            &:focus {
                outline: none;
                border-bottom: 2px solid $brand-border;
            }
        }
        .input-textbox-error {
            border: none;
            border-bottom: solid 2px #b00020;
        }
        .input-textbox-label {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
        }
        .forgotten-access-code-link {
            color: #4f4c43;
        }
    }
}
