@import './light-booking-summary-ford.scss';

.brand-lincoln {
    .osb-light-journey-booking-summary {
        .osb-review-booking-component-container {
            .review-booking-title-style {
                font-family: $proxima-nova-semi-bold;
                color: #000;
            }
            .osb-review-booking-container {
                .dealer-error-message {
                    color: $brand-border;
                    font-family: $proxima-nova-light;
                }
                .dealer-section {
                    .dealer-title {
                        font-family: $proxima-nova-semi-bold;
                        color: $brand-secondary;
                    }
                    .dealer-name {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                    }
                    .dealer-address {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                    }
                    .change-link {
                        color: #4f4c43;
                        font-family: $proxima-nova-regular;
                    }
                }
                .vehicle-section {
                    .vehicle-title {
                        color: $brand-secondary;
                        font-family: $proxima-nova-semi-bold;
                    }
                    .vehicle-name {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                    }
                    .change-link {
                        color: #4f4c43;
                        font-family: $proxima-nova-regular;
                    }
                }
                .osb-light-booking-summary-service-section {
                    .service-title {
                        font-family: $proxima-nova-semi-bold;
                        color: $brand-secondary;
                    }
                    .services-list {
                        .single-service {
                            .service-name {
                                color: $brand-secondary;
                                font-family: $proxima-nova-regular;
                            }
                            .service-price {
                                font-family: $proxima-nova-regular;
                                color: $brand-secondary;
                                .discountedPrice {
                                    color: $brand-border;
                                    font-family: $proxima-nova-regular;
                                }
                            }
                        }
                    }
                    .service-change-link {
                        color: #4f4c43;
                        font-family: $proxima-nova-regular;
                    }
                }

                .datetime-section {
                    .datetime-title {
                        font-family: $proxima-nova-semi-bold;
                        color: $brand-secondary;
                    }
                    .date-time {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                    .change-link {
                        color: #4f4c43;
                        font-family: $proxima-nova-regular;
                    }
                }
                .light-review-voucher-title {
                    color: $brand-secondary;
                    .voucher-section {
                        .voucher-code {
                            font-family: $proxima-nova-regular;
                        }
                    }
                }
                .invalid-voucher {
                    color: $brand-border;
                    font-family: $proxima-nova-regular;
                }
                .light-voucher-des {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }

                .add-voucher-link,
                .light-remove-voucher-link {
                    color: #4f4c43;
                }
                .total-price-section {
                    .total-price-label {
                        font-family: $proxima-nova-semi-bold;
                        color: $brand-secondary;
                    }
                    .total-price {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                        .discountedPrice {
                            color: $brand-border;
                        }
                    }
                }
                .service-sub-title {
                    font-family: $proxima-nova-semi-bold;
                    color: $brand-secondary;
                }
                .change-link {
                    color: #4f4c43;
                    font-family: $proxima-nova-regular;
                }
            }

            .service-change-link {
                color: #4f4c43;
            }
        }
    }

    .osb-light-journey-booking-summary::-webkit-scrollbar-thumb {
        background-color: $brand-secondary;
    }
}
