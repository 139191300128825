@import '../../../../../styles/main.scss';

.osb-light-journey-personal-details-page {
    background-color: $fds-color-white;
    width: 100%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: 65% 33%;
    grid-column-gap: 2%;
    @media only screen and (max-width: 768px) and (min-width: 280px) {
        display: block;
        width: 90%;
        flex-direction: row;
    }
    @include tablet-portrait {
        width: 100%;
    }
    .osb-personal-details-section {
        background-color: $scard-light-grey;
        padding: 20px 30px;
        margin-bottom: 30px;
        border-radius: 16px;

        @include tablet-portrait {
            height: auto;
            padding: 20px 25px;
        }
        .osb-light-journey-personal-details-flow-container {
            width: 100%;
            .osb-secondary-button {
                padding-left: 0;
                @include mobile {
                    padding-bottom: 20px;
                }
            }

            @media only screen and (max-width: 1150px) {
                width: 100%;
                padding: 0 30px;
            }

            @include tablet-portrait {
                padding: 0 20px;
            }
            @include mobile {
                padding: 0;
            }
            .personal-details-flow-header {
                font-family: $f1-font-regular;
                font-size: 32px;
                line-height: 40px;
                color: $fds-color-primary;
                text-align: start;
                margin: 20px 0;
                @include mobile {
                    font-size: 20px;
                    line-height: 30px;
                }

                @include tablet-portrait {
                    text-align: left;
                }
            }
            .personal-details-flow-sub-header {
                font-size: 11px;
                font-family: $f1-font-regular;
                line-height: 16px;
                color: #4d4d4d;
                padding-bottom: 20px;
                margin-top: 20px;
                width: 500px;
                @include mobile {
                    width: 100%;
                }

                @include tablet-portrait {
                    text-align: left;
                    padding-top: 12px;
                    width: 100%;
                }
                a {
                    color: #00095b;
                    cursor: pointer;
                }
            }
            .osb-personal-details-form {
                display: grid;
                grid-template-columns: 100%;
                margin: 0;
                @include tablet-portrait {
                    width: 100%;
                }
            }
            .osb-personal-details-title {
                width: 500px !important;
                font-size: 100%;
                line-height: 1.63;
                color: #4d4d4d;
                margin-bottom: 5px;
                @include tablet-portrait {
                    width: 100% !important;
                    margin-left: 0;
                }

                .dropdown-button {
                    background-color: #fff !important;
                    width: 393px;
                    border-radius: 8px;
                    border: 1px solid $fds-color-lt-gray-b;
                    height: 40px;
                    @include tablet-portrait {
                        width: 100% !important;
                    }
                    @include mobile {
                        width: 100% !important;
                    }
                }
            }
            .dropdown-current-item {
                color: #00095b !important;
            }
            .dropdown-items-panel {
                min-width: 47.5% !important;
            }

            .input-field,
            .input-field-error {
                width: 100% !important;
                @include tablet-portrait {
                    width: 100% !important;
                }
            }

            .input-field {
                border: 1px grey solid;
                height: 52px;
            }
            .primary-button {
                outline: none;
                width: fit-content;
            }

            .button-container {
                display: grid;
                grid-template-columns: 73% 27%;
                padding: 30px 0;

                @include mobile {
                    grid-template-columns: 100%;
                    margin-left: 0;
                }

                .first-button {
                    justify-content: center;
                    @include mobile {
                        margin-bottom: 15px;
                    }
                }
                .second-button {
                    justify-content: center;
                }
            }
            .osb-personal-details-first-name,
            .osb-personal-details-last-name,
            .osb-personal-details-contact-number,
            .osb-personal-details-email,
            .osb-personal-details-country-dial-code,
            .osb-personal-details-registration-number {
                font-size: 100%;
                line-height: 26px;
                color: #4d4d4d;
                float: left;
                width: 393px;
                margin: 10px 0;
                @include tablet-portrait {
                    width: 100% !important;
                    margin-left: 0;
                }

                input,
                label {
                    padding-top: 0px;
                    font-size: 11px;
                    @include mobile {
                        line-height: 16px;
                    }
                    @include tablet-portrait {
                        width: 100%;
                    }
                }

                input {
                    color: $fm-text-grey;
                    font-size: 14px;
                    border: 1px solid #b2b2b2;
                    height: 40px;
                    border-radius: 8px;
                }

                .osb-error-icon {
                    float: right;
                    width: 20px;
                    margin-bottom: 3px;
                }

                .osb-error-message {
                    top: -1.8rem;
                    color: rgba(214, 45, 20, 1);
                    font-size: 0.75rem;
                    line-height: 1.313rem;
                }
            }
            .osb-personal-details-country-dial-code {
                input {
                    border: none !important;
                }
            }
            .consent-checkbox-container {
                font-size: 12px;
                line-height: 1.63;
                color: #4d4d4d;
                width: auto;
                .consent-checkbox-label {
                    display: block;
                    padding-left: 15px;
                    text-indent: -15px;
                    .consent-checkbox {
                        margin-right: pToR(19);
                        margin-top: 19px;
                        &:before {
                            display: inline-block;
                            color: $fds-color-primary;
                            width: pToR(17);
                            height: pToR(17);
                            background-color: rgba(255, 255, 255, 1);
                            border: 2px solid $fds-color-dk-gray;
                            border-radius: 3px;
                            content: '';
                            margin-right: pToR(19);
                            box-sizing: border-box;
                            text-align: center;
                            padding: 0;
                            font-size: 10px;
                        }
                        &:checked {
                            &:before {
                                content: '\2713';
                                border-color: #00095b;
                                color: #00095b;
                            }
                        }
                    }
                    .consent-checkbox:disabled::before {
                        border: 2px solid #cccccc !important;
                    }
                }
                .consent-checkbox-section {
                    padding-bottom: 15px;
                }
            }
            .osb-loading {
                position: fixed;
                left: 0;
                right: 0;
                top: 250px;
                bottom: 0;
                align-items: center;
                justify-content: center;
                z-index: 50;
            }
            .osb-save-to-my-ford-account-container {
                width: auto;
                display: flex;
                margin-top: 20px;
                input[type='checkbox'] {
                    appearance: none;
                }
                .save-to-my-ford-account-checkbox {
                    margin-top: 6px;
                    margin-right: pToR(19) !important;
                    &:before {
                        margin-right: 0px !important;
                        display: inline-block;
                        color: $fds-color-primary;
                        width: pToR(17);
                        height: pToR(17);
                        background-color: #fff;
                        border: 2px solid $fds-color-dk-gray;
                        border-radius: 3px;
                        content: '';
                        box-sizing: border-box;
                        text-align: center;
                        padding: 0;
                        font-size: 10px;
                    }

                    &:checked {
                        &:before {
                            content: '\2714' !important;
                            border-color: #00095b;
                            color: #00095b;
                        }
                    }
                }
                .osb-save-to-my-ford-account-label {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.63;
                    color: #4d4d4d;
                }
            }
        }
    }
}
