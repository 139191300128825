.brand-ford {
    .quick-links-grid-wrapper {
        margin: auto 16px 30px 16px;
        @include desktop() {
            margin-left: 57px;
        }
        .quick-links-header {
            color: $fds-color-primary;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
        }
        .preimagine-quick-links-header {
            color: $f1-text-blue;
            font-size: 40px;
            line-height: unset;
            @include desktop {
                font-size: 54px;
                padding-left: unset;
            }
        }
        .quick-links-subcopy {
            font-family: $f1-font-regular;
            color: $f1-text-blue;
            margin-top: 16px;
            font-size: 16px;
            margin-bottom: 23px;
        }
        hr {
            margin-bottom: 20px;
            box-shadow: none;
        }

        .quick-links-card-wrapper {
            display: flex;
            height: auto;
            flex-wrap: wrap;

            .fm-list-item-container {
                width: 220px;
                flex-grow: 1;
                @include desktop() {
                    min-width: unset;
                    flex-grow: 0;
                    width: calc((100% / 4) - 16px);
                }
                min-height: 58px;
                border-color: $border-neutral;
                .fm-list-item__title {
                    color: $f1-text-blue;
                }
            }
        }
    }
    .quick-links-show-all {
        box-shadow: none;
        justify-content: center;
        display: flex;
        padding-top: 20px;
        .show-all-button {
            justify-content: center;
            .plus-circle {
                margin-left: 10px;
            }
        }
    }
    .preimagine-quick-links-show-all {
        box-shadow: none;
        display: flex;
        padding-top: 20px;
    }
}
