@import '../../../../styles/main';
.brand-ford {
    .preimagine-escape-hatch {
        display: flex;
        flex-direction: column;
        justify-content: left;
        text-align: left;
        padding: 0 16px;
        margin: 48px 0 160px 0; // this is a buffer for the autocomplete for the search bar
        @include desktop {
            padding: 0 56px;
            margin: 96px 0 250px 0; // this is a buffer for the autocomplete for the search bar
        }
        .search-container {
            align-items: start;
        }
        .escape-hatch-hatches {
            display: flex;
            align-items: start;
            flex-direction: column;
            gap: 24px;
            @include desktop {
                flex-direction: row;
                gap: 32px;
            }
            .escape-hatch-escape-button {
                display: flex;
                align-items: center;

                .escape-hatch-escape-button-img {
                    margin-left: 8px;
                }
            }
        }
    }
}
