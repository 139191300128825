@import '../../../../../styles/main.scss';

.light-journey-delivery-options {
    background-color: $fds-color-white;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-column-gap: 2%;
    grid-template-columns: 65% 33%;
    @media only screen and (max-width: 1200px) {
        margin: 0 auto;
    }

    @include tablet-portrait {
        display: block;
        width: 100%;
        grid-template-columns: 100%;
        grid-column-gap: 0;
    }
    .delivery-options-container {
        background-color: $scard-light-grey;
        margin-bottom: 30px;
        padding: 2% 5%;
        border-radius: 16px;
        @include desktop {
            .osb-secondary-button {
                padding: 4% 0 20px 0%;
            }
        }
        @include mobile {
            background-color: $fds-color-white;
        }

        @include tablet-portrait {
            padding: 20px 25px;
        }
        .continue-button {
            .primary-button {
                @include mobile {
                    width: 100%;
                }
            }
        }
        .delivery-options-title {
            font-family: $f1-font-regular;
            font-size: 32px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            color: #102b4e;
            margin-bottom: 35px;
            @include mobile {
                width: 100%;
                margin: 30px 0;
                font-size: 20px;
            }
        }
        .delivery-option-service-list {
            padding-right: 18px;
            .osb-check-box-spacing {
                display: flex;
                justify-content: space-between;
                @include mobile {
                    flex-direction: column;
                }
                .checkbox-content {
                    display: flex;
                    align-items: center;
                    @include mobile {
                        text-align: left;
                        align-items: flex-start;
                    }
                    .service-description {
                        text-align: left;

                        label {
                            vertical-align: top;
                            margin-right: 10px;
                        }
                        .offer-label {
                            background-color: #008200;
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            color: $fds-color-white;
                            width: fit-content;
                            margin-bottom: 5px;
                            padding: 2px 5px;
                        }
                        .checkbox-label {
                            @media screen and (max-width: 320px) {
                                font-size: 13px;
                            }
                        }
                        .service-disclaimers {
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            line-height: 16px;
                            color: $fds-color-dk-gray;
                            margin-bottom: 10px;
                            margin-top: 10px;
                        }
                    }
                }
                .price-details {
                    color: $fds-color-dk-gray;
                    @include mobile {
                        padding-left: 33px;
                        text-align: left;
                    }
                    @media screen and (max-width: 320px) {
                        font-size: 13px;
                    }
                    .service-checkbox-price-info {
                        display: flex;
                        .price-on-request {
                            margin-top: 5px;
                        }
                    }
                }
                .tooltip.info-icon-tooltip {
                    margin-left: 0px;
                    .tooltip__infoIcon {
                        &.dark {
                            -webkit-text-fill-color: #00095b;
                            font-size: 22px;
                            // top: -4px;
                            // @media (max-width: 1366px) {
                            //     top: -5px;
                            // }
                        }
                    }
                }
                .service-price-section {
                    font-size: 14px;
                    font-family: $f1-font-bold;
                    line-height: 1.63;
                    color: $dyf-color-darkgrey;
                    text-align: right;
                    @include mobile {
                        text-align: start;
                        margin-left: 40px;
                    }
                    p {
                        @include mobile {
                            display: flex;
                        }
                    }
                    .hasDiscount {
                        text-decoration: line-through;
                        color: red;
                    }
                }
            }
        }
        .delivery-option-price-disclaimers {
            margin: 50px 0;
            div {
                font-size: 11px;
                line-height: 1.91;
                color: #4d4d4d;
            }
        }
    }
    .osb-light-journey-booking-summary {
        grid-column-start: 2;
        grid-row-start: 1;
    }
}
