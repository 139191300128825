@import './light-personal-details-flow-ford.scss';

.brand-lincoln {
    .osb-light-journey-personal-details-page {
        .osb-personal-details-section {
            .osb-light-journey-personal-details-flow-container {
                .personal-details-flow-header {
                    font-family: $proxima-nova-regular;
                    color: #000;
                    letter-spacing: 1px;
                }
                .personal-details-flow-sub-header {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
                .dropdown-container-wrapper {
                    .label {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                }
                .dropdown-button {
                    background-color: #ecedee !important;
                    .dropdown-current-item {
                        color: #324047 !important;
                    }
                }
                .input-field {
                    border: none;
                    border-bottom: solid 1px #6e6e6e;
                    background-color: #ecedee;
                    font-family: $proxima-nova-regular;
                    &:hover {
                        border-bottom: solid 2px #324047;
                    }
                    &:focus {
                        outline: none;
                        border-bottom: solid 2px $brand-border;
                    }
                }
                .input-field-error {
                    font-family: $proxima-nova-regular;
                    color: #324047;
                    background-color: #efefef;
                    border: none;
                    border-bottom: solid 2px #b00020;
                    &:focus {
                        outline: none;
                    }
                }
                .osb-personal-details-first-name,
                .osb-personal-details-last-name,
                .osb-personal-details-contact-number,
                .osb-personal-details-email,
                .osb-personal-details-registration-number {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                    input {
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                    }
                    .osb-error-message {
                        color: $brand-light-error;
                    }
                }
                .consent-checkbox-container {
                    color: $brand-secondary;
                    .consent-checkbox-label {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                        .consent-checkbox {
                            &:before {
                                color: $brand-secondary;
                                background-color: rgba(255, 255, 255, 1);
                                border: 2px solid $brand-secondary;
                            }
                            &:checked {
                                &:before {
                                    content: '\2714';
                                    width: 20px;
                                    height: 20px;
                                    font-size: 16px;
                                    border-color: #f26147;
                                    background-color: #f26147;
                                    color: #fff;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 0;
                                }
                            }
                            a {
                                color: #4f4c43;
                                font-family: $proxima-nova-regular;
                            }
                        }
                    }
                }
                .osb-save-to-my-ford-account-container {
                    .osb-save-to-my-ford-account-label {
                        font-family: $proxima-nova-regular;
                        color: $brand-secondary;
                    }
                }
            }
        }
    }
}
