@import '../../../../../../styles/main.scss';

.light-specific-service-wrapper {
    display: grid;
    background-color: #f4f4f4;
    @include mobile {
        background-color: $fds-color-white;
    }

    .light-journey-specific-page {
        background-color: #f4f4f4;
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-column-gap: 5%;
        @include mobile {
            margin: 0;
            width: 100%;
        }
        .light-specific-service {
            width: 100%;
            padding: 5%;
            background-color: $fds-color-white;
            @include mobile {
                width: 100%;
                margin: 0;
                padding: 0;
            }
            .osb-secondary-button {
                padding: 0;
            }

            .specific-service-header {
                margin: 30px 0 30px;
                font-family: $f1-font-regular;
                font-size: 32px;
                line-height: 40px;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 20px;
                    margin: 20px 0 30px;
                }
            }
            .services-header {
                font-family: $f1-font-regular;
                font-size: 24px;
                line-height: 1.5;
                color: $fds-color-primary;
                margin: 40px 0 35px;
                text-transform: capitalize;
                @include mobile {
                    font-size: 20px;
                    margin: 35px 0 25px;
                }
            }

            .hr-line-style {
                border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                padding-top: 30px;

                @include mobile {
                    display: none;
                }
            }
            .services-list {
                .osb-check-box-spacing {
                    display: flex;
                    justify-content: space-between;
                    @include mobile {
                        flex-direction: column;
                    }
                    .checkbox-content {
                        display: flex;
                        width: 75%;
                        @include mobile {
                            width: 100%;
                            text-align: left;
                        }
                        .service-description {
                            text-align: left;

                            label {
                                vertical-align: top;
                                margin-right: 10px;
                            }
                            .offer-label {
                                background-color: #008200;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                letter-spacing: 1px;
                                color: $fds-color-white;
                                width: fit-content;
                                margin-bottom: 5px;
                                padding: 2px 5px;
                            }
                            .checkbox-label {
                                @media screen and (max-width: 320px) {
                                    font-size: 13px;
                                }
                            }
                            .service-disclaimers {
                                font-family: $f1-font-regular;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                color: $fm-text-grey;
                                margin-top: 5px;
                            }
                        }
                    }
                    .price-details {
                        color: #4d4d4d;
                        @include mobile {
                            padding-left: 33px;
                            text-align: left;
                        }
                        @media screen and (max-width: 320px) {
                            font-size: 13px;
                        }
                        .service-checkbox-price-info {
                            display: flex;
                            .price-on-request {
                                margin-top: 5px;
                            }
                        }
                    }
                    .tooltip.info-icon-tooltip {
                        margin-left: 0px;
                        .tooltip__infoIcon {
                            &.dark {
                                -webkit-text-fill-color: $fds-color-primary;
                                font-size: 22px;
                                top: -4px;
                                background-color: $fds-color-white;
                                @media (max-width: 1366px) {
                                    top: -4px;
                                }
                            }
                        }
                    }
                    .service-price-section {
                        font-size: 16px;
                        font-family: $f1-font-bold;
                        line-height: 1.63;
                        color: #4d4d4d;
                        text-align: right;
                        @include mobile {
                            text-align: start;
                            margin-left: 11%;
                        }
                        p {
                            @include mobile {
                                display: flex;
                            }
                        }

                        .hasDiscount {
                            text-decoration: line-through;
                            color: red;
                        }
                    }
                }
            }
            .ss-comments {
                margin: 35px 0 35px;
                width: 100%;
                @include mobile {
                    width: 100%;
                }
                .comments-section-title {
                    font-family: $f1-font-medium;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    color: $breadcrumb-blue;
                    margin: 40px 0 25px;
                    @include mobile {
                        margin: 35px 0 25px;
                    }
                }
                .comment-container {
                    .comment-input textarea {
                        width: 47%;
                        height: 170px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #4d4d4d;
                        border: 1px solid $grey;
                        border-radius: 8px;
                        padding: 12px 12px;
                        @include mobile {
                            width: 100%;
                            height: 100px;
                        }
                        &:focus-visible {
                            outline: none;
                        }
                    }
                    .comment-empty-error {
                        border: solid 1px #d62d14;
                        background-color: #ffffff;
                        box-shadow: none;
                    }
                }
            }
            .price-disclaimer {
                margin: 50px 0 0;
                font-family: $f1-font-regular;
                font-size: 11px;
                line-height: 1.91;
                color: #4d4d4d;
            }
            .continue-button {
                margin: 65px 0 85px;
                @include mobile {
                    margin: 55px 0 85px;
                    display: flex;
                }
            }
            .tooltip .tooltip__wrapper {
                @include mobile {
                    margin-left: 80px;
                    width: 200px;
                }
                @media screen and (max-width: 320px) {
                    margin-left: 120px;
                    width: 180px;
                }
            }
        }
    }
}
.osb-new-service-listing {
    .brake-fluid-disclaimer {
        padding: 0.5rem;
        margin-top: 0;
        font-family: $f1-font-medium;
        font-size: 0.75rem;
        font-stretch: normal;
        font-style: normal;
        line-height: 17px;
        color: #00095b;
        p {
            margin: 0;
        }
    }
    border-bottom: 1px solid $grey;
    .accordion-button {
        min-height: 50px;
        // border: 1px solid #4d4d4d;
        display: flex;
        padding: 30px 16px;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        color: inherit;
        background-color: inherit;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
        width: 100%;
        .services-title {
            font-family: $f1-font-medium;
            color: $fm-breadcrumb-blue;
            text-transform: capitalize;
            font-size: 24px;
            white-space: break-spaces;
            text-align: start;
            width: 95%;
            font-weight: 500;
            line-height: 32px;
            position: relative;
            span {
                margin-left: 10px;
                cursor: pointer;
                text-decoration: underline;
                font-size: 12px;
                text-transform: capitalize;
            }
            &:after,
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                right: 0.5em;
                width: 0.75em;
                height: 1px;
                background: $fm-breadcrumb-blue;
                transition: transform var(--trans-dur) var(--ease-in-out);
            }
            &:after {
                transform: rotate(-90deg);
            }
            &.close {
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
        .selected-services {
            text-align: center;
            margin: auto;
            color: $fds-color-white;
            width: 25px;
            height: 25px;
            background-color: $fm-skyview;
            border-radius: 10%;
            padding: 8px;
            font-size: 12px;
            font-family: $f1-font-regular;
        }
    }
    .new-service-section {
        // margin: 20px 0;
        padding: 0;
        //margin-bottom: 50px;

        .service-detail-container {
            display: flex;
            background-color: $white-smoke;
            padding: 16px 24px;
            margin: 10px 0 15px;
            border-radius: 10px;
            @include mobile {
                flex-direction: column;
                margin: 0 0 25px 0;
            }
            .hr-line-style {
                @include mobile {
                    border-bottom: 1px solid $lightgrey;
                    margin: 20px 0;
                    display: block;
                    padding: 0;
                }
            }
            .service-details {
                width: 70%;
                @include mobile {
                    width: 100%;
                }
                .service-name {
                    font-family: $f1-font-medium;
                    font-size: 24px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 32px;
                    color: $fds-color-primary;
                    @include mobile {
                        font-size: 20px;
                        line-height: 28px;
                    }
                    .tooltip {
                        border: none;
                        .tooltip__infoIcon {
                            &.dark {
                                background-color: #f4f4f4;
                            }
                        }
                    }
                }
                .service-offer {
                    background-color: $fds-color-primary;
                    font-family: $f1-font-regular;
                    font-size: 10px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: 1px;
                    color: $fds-color-white;
                    width: fit-content;
                    margin: 10px 0;
                    padding: 5px;
                    border-radius: 3px;
                }
                .service-disclaimers {
                    // margin: 0px 77px 0px 10px;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    font-weight: 400;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 20px;
                    color: $fm-text-grey;
                    margin-top: 5px;
                    @include mobile {
                        margin-top: 10px;
                    }
                }
            }
            .service-other-section {
                margin: auto;
                width: 30%;
                display: flex;
                // margin: auto;
                flex-direction: column;
                justify-content: space-between;
                @include mobile {
                    width: 100%;
                }
                .service-select-section {
                    margin: 10px 0;
                    .fmc-button {
                        font-family: $f1-font-bold;
                        float: right;
                        font-size: 14px;
                        line-height: 13px;
                        font-weight: 700;
                        justify-content: center;
                        align-items: center;
                        background: transparent;
                        color: $fds-color-white;
                        border: none;
                        padding: 0 30px;
                        min-height: 40px;
                        height: auto;
                        text-decoration: none;
                        position: relative;
                        &:after {
                            background-color: $fm-skyview;
                            border: 0;
                        }
                        @include mobile {
                            float: none;
                            margin: auto;
                            display: block;
                            width: 100%;
                        }
                        .fds-icon {
                            font-size: 15px;
                            --offset: 0.6rem;
                        }
                    }
                    .fmc-button--outlined {
                        color: $fm-skyview;
                        &:after {
                            background-color: $fds-color-white;
                            border: 1px solid $fm-skyview;
                        }
                    }
                }
                .service-price-section {
                    .service-price {
                        color: $fm-text-grey;
                        float: right;
                        display: flex;
                        font-size: 16px;
                        font-family: $f1-font-bold;
                        font-weight: 700;
                        @include mobile {
                            float: none;
                            margin: auto;
                            display: block;
                        }
                    }
                    font-size: 16px;
                    font-family: $f1-font-semibold;
                    line-height: 1.63;
                    letter-spacing: 1px;
                    color: $fds-color-primary;
                    text-align: end;
                    @include mobile {
                        text-align: center;
                    }

                    .hasDiscount {
                        text-decoration: line-through;
                        color: #d62d14;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .accordion-hide {
        display: none;
    }
    .accordion-show {
        display: block;
    }
}

@keyframes accordion-minus {
    from {
        transform: rotate(-90deg);
    }
    to {
        transform: rotate(0);
    }
}
@keyframes accordion-plus {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-90deg);
    }
}
@keyframes accordion-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes accordion-fade-slide-out {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-0.75em);
    }
}
