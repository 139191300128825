@import '../../../../../styles/main.scss';
.light-retrive-booking-component-container {
    width: 100%;
    background: $scard-light-grey;
    display: grid;
    grid-template-columns: 100%;
    padding: 4%;
    font-family: $f1-font-regular;
    border-radius: 16px;

    @include mobile {
        background: $fds-color-white;
    }

    .light-retrive-booking-back {
        grid-row-start: 1;
        grid-column-start: 1;
        display: flex;
        flex-direction: row;
        visibility: visible;
        line-height: 3rem;

        .osb-secondary-button {
            display: grid;
            white-space: nowrap;
            grid-template-columns: 50% 50%;
            margin-top: 15px;
            padding: 0;
        }
    }
    .light-retrieve-booking-title {
        grid-row-start: 2;
        grid-column-start: 1;
        text-transform: uppercase;
        font-size: 34px;
        font-family: $f1-font-light;
        line-height: 1.29;
        color: #00095b;
        padding-top: 20px;
        @media only screen and (max-width: 768px) and (min-width: 481px) {
            font-size: 24px;
        }
        @media only screen and (max-width: 480px) and (min-width: 321px) {
            font-size: 20px;
            text-align: left;
        }
        @media only screen and (max-width: 320px) and (min-width: 280px) {
            font-size: 16px;
            text-align: left;
        }
    }
    .modal {
        .modal-content {
            width: 35%;
            border-radius: 16px;
            @include mobile {
                width: 90%;
            }
        }
        .model-heading {
            text-align: center;
            font-family: $f1-font-regular;
            font-size: 32px;
            line-height: 40px;
            color: $fds-color-primary;
        }
        .modal-footer {
            .osb-modal-footer-buttons {
                button {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .light-retrieve-amend-booking-button {
        grid-row-start: 6;
        grid-column-start: 1;
        width: 100%;
        margin-top: 30px;
        display: flex;
        text-align: left;

        .amend-confirmation-modal {
            background: rgba(0, 0, 0, 0.8);
        }
        .modal-body {
            font-size: 16px;
            line-height: 1.63;
            color: #4d4d4d;
            margin-bottom: 10px;
        }

        .secondary {
            width: 180px;
            min-width: 100px;
            height: 40px;
            margin-left: 20px;
            object-fit: contain;
            border-radius: 20px;
            border: solid 1px #00095b;
            margin-bottom: 30px;
        }
        .modal-footer {
            @media only screen and (max-width: 948px) {
                display: grid;
                grid-row-start: 2;
                .secondary {
                    grid-row-start: 1;
                    margin-left: 0;
                }
            }
        }
    }
    .light-retrieve-cancel-button {
        grid-row-start: 7;
        grid-column-start: 1;
        width: 100%;
        margin-top: 25px;
        display: flex;
        text-align: left;

        .cancel-booking-modal {
            background: rgba(0, 0, 0, 0.8);
        }

        .modal-body {
            font-size: 14px;
            line-height: 20px;
            color: $fm-text-grey;
            margin-bottom: 10px;
        }

        .secondary {
            width: auto;
            min-width: 100px;
            height: 40px;
            margin-left: 20px;
            object-fit: contain;
            border-radius: 20px;
            border: solid 1px #00095b;
            margin-bottom: 30px;
        }
        .modal-footer {
            @media only screen and (max-width: 948px) {
                display: grid;
                grid-row-start: 2;
                .secondary {
                    grid-row-start: 1;
                    margin-left: 0;
                }
            }
        }
    }
    .light-retrieve-logout-button {
        grid-row-start: 8;
        grid-column-start: 1;
        margin-top: 50px;
        width: auto;
        display: flex;
        flex-direction: row;
        visibility: visible;
        .fds-dot {
            color: #00095b;
            cursor: pointer;
        }

        .light-retrive-logout-button-label {
            font-family: $f1-font-bold;
            font-size: 14px;
            line-height: 20px;
            color: $fm-breadcrumb-blue;
            cursor: pointer;
            margin-right: 10px;
        }

        @media only screen and (max-width: 948px) {
            justify-content: flex-start;
        }

        .model-heading {
            text-align: center;
            font-family: $f1-font-regular;
            font-size: 20px;
            line-height: 1.5;
            color: #4d4d4d;
            text-transform: uppercase;
        }

        .modal-body {
            font-size: 16px;
            line-height: 1.63;
            color: #4d4d4d;
            margin-bottom: 10px;
        }

        .secondary {
            width: auto;
            min-width: 100px;
            height: 40px;
            margin-left: 20px;
            object-fit: contain;
            border-radius: 20px;
            border: solid 1px #00095b;
            margin-bottom: 30px;
        }
        .modal-footer {
            @media only screen and (max-width: 948px) {
                display: grid;
                grid-row-start: 2;
                .secondary {
                    grid-row-start: 1;
                    margin-left: 0;
                }
            }
        }
    }
    .primary-button {
        width: 250px;
        @include mobile {
            width: 100%;
        }
    }
    .osb-loading {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        content: '';
        width: 100%;
        background: rgba(246, 246, 246, 0.9);
        height: 100%;
        display: block;
        z-index: 2005;
        &:after {
            // position: absolute;
            content: '';
            width: 100%;
            background: rgba(246, 246, 246, 0.9);
            height: 100%;
            display: block;
            z-index: 2003;
            left: 0;
            top: 0;
        }

        // .fds-activity-indicator {
        //     top: 350px;
        // }
    }
    .light-retrieve-amend-booking-button,
    .light-retrieve-cancel-button,
    .light-retrieve-logout-button {
        .fds-chevron-wrap {
            color: $fm-breadcrumb-blue;
        }
        .modal-footer-buttons {
            column-gap: 30px;
            .primary-button {
                padding: 10px 30px;
                .button-label {
                    white-space: break-spaces;
                }
            }
            .color-outline {
                color: #00095b;
                &::before {
                    background-color: transparent;
                    border: 1px solid #00095b;
                    box-shadow: none;
                }
            }
        }
    }
    .osb-file-uploader-container {
        .osb-drag-drop-container {
            .osb-drag-drop-panel {
                @include mobile {
                    border: 1px solid gray;
                    display: flex;
                    height: 40px;
                    width: 100%;
                    align-items: center;
                }

                // width: 100%;
                // display: block;
                // height: 40px;
                div {
                    @include mobile {
                        display: flex;
                        height: 40px;
                        padding-left: 10px;
                        align-items: center;
                        width: 100%;
                    }
                    // display: block;
                    // height: 40px;
                }
            }
        }
    }
}
