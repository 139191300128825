@import '../../../../styles/main.scss';
.ford-account-section {
    margin-top: 30px;
    .ford-account-title {
        height: 44px;
        font-family: $f1-font-regular;
        font-size: 24px;
        line-height: 34px;
        color: #00095b;
    }
    .ford-desc-container {
        width: 100%;
        margin: 16px 0 10px;
        font-size: 16px;
        line-height: 26px;
        color: #4d4d4d;
    }
    .ford-button-container {
        font-size: 16px;
        line-height: 1.63;
        padding-top: 22px;
        display: grid;
        grid-template-columns: 30% 20% 50%;
        @media only screen and (max-width: 1024px) and (min-width: 280px) {
            grid-template-columns: 100%;
            justify-items: center;
        }

        .login-btn {
            font-family: $f1-font-regular;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            .primary-button {
                min-height: 40px;
                display: grid;
                .button-label.no-chevron {
                    white-space: break-spaces;
                }
            }
        }

        .or-style {
            margin-top: 4px;
            font-size: 16px;
            padding-top: 5px;
            line-height: 26px;
            color: #4d4d4d;
            text-align: center;
        }
        .register-btn {
            display: flex;
            color: #00095b;
            text-decoration: none;
            p {
                margin-right: 10px;
                padding-top: 8px;
            }
        }
        .osb-secondary-button {
            padding: 10px 0;
            ul li {
                padding-right: 10px;
            }
        }
    }
}
