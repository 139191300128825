@import './ford-account-ford.scss';

.brand-lincoln {
    .ford-account-section {
        .ford-account-title {
            font-family: $proxima-nova-regular;
            color: #000;
            @include mobile {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
        }
        .ford-desc-container {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-weight: 300;
        }
        .ford-button-container {
            .login-btn {
                font-family: $proxima-nova-regular;
            }

            .or-style {
                margin-top: 8px;
                color: $brand-secondary;
            }
            .osb-secondary-button {
                padding: 13px 0;
            }
        }
    }
}
