@import './light-specific-service-ford.scss';

.brand-lincoln {
    .light-specific-service-wrapper {
        .light-journey-specific-page {
            .light-specific-service {
                .specific-service-header,
                .services-header {
                    color: #324047;
                    font-family: $proxima-nova-regular;
                }
                .services-list {
                    .osb-check-box-spacing {
                        .tooltip .tooltip__infoIcon {
                            &.dark {
                                -webkit-text-fill-color: #324047;
                            }
                        }
                        .checkbox-content {
                            .checkbox {
                                &:before {
                                    width: 20px;
                                    height: 20px;
                                    background-color: #fff;
                                    border-color: #324047;
                                    border-radius: 0;
                                }
                                &:checked {
                                    &:before {
                                        content: '\2714';
                                        width: 20px;
                                        height: 20px;
                                        font-size: 16px;
                                        border-color: #f26147;
                                        background-color: #f26147;
                                        color: #fff;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 0;
                                    }
                                }
                            }
                            .service-description {
                                .offer-label {
                                    background-color: #f26147;
                                    font-family: $proxima-nova-regular;
                                }
                                .checkbox-label,
                                .service-disclaimers {
                                    font-family: $proxima-nova-regular;
                                    color: #324047;
                                }
                            }
                        }
                        .service-price-section {
                            font-family: $proxima-nova-bold;
                            color: #324047;
                            .hasDiscount {
                                color: #f26147;
                            }
                        }
                    }
                }
            }
        }
    }
}
