@import '../../../../styles/main.scss';
@import '../campaign-validation/campaign-validation-variables.scss';
.osb-light-journey-booking-summary {
    background-color: #ffffff;
    margin-bottom: 30px;
    font-size: 20px;
    .osb-review-booking-component-container {
        padding: 6%;
        background: #fafafa;
        border-radius: 16px;
        .review-booking-title-style {
            width: 100%;
            text-transform: capitalize;
            font-family: $f1-font-regular;
            font-size: 20px;
            line-height: 28px;
            font-weight: 400;
            text-align: center;
            color: $fm-text-grey;
            white-space: break-spaces;
            word-break: break-word;
            margin-top: 15px;
        }
        .osb-review-booking-container {
            display: block;
            margin: 0 0 0 0;
            .services-flow-error {
                color: #d62d14;
                font-family: $f1-font-light;
                font-size: 16px;
                line-height: 1.313rem;
                white-space: break-spaces;
                text-align: start;
            }
            .edit-section {
                display: flex;
            }
            .vehicle-section {
                grid-column: 1;
                grid-row: 1;
                .vehicle-title {
                    width: 70%;
                    margin-bottom: 13px;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    color: $fds-color-primary;
                }
                .vehicle-name {
                    width: 100%;
                    margin: 0 0 16px 1px;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    color: #00095b;
                }
                .change-link {
                    font-size: 14px;
                    text-transform: capitalize;
                    text-align: right;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                }
            }
            .dealer-section {
                .dealer-title {
                    line-height: 20px;
                    font-family: $f1-font-bold;
                    font-size: 14px;
                    color: $fm-breadcrumb-blue;
                    width: 70%;
                    margin-bottom: 12px;
                    font-weight: 700;
                    font-stretch: normal;
                    font-style: normal;
                }
                .dealer-name {
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    text-transform: capitalize;
                }
                .dealer-address {
                    line-height: 20px;
                    color: $sidebar-dark-grey;
                    font-size: 14px;
                    font-family: $f1-font-regular;
                    font-weight: 400;
                }
                .change-link {
                    font-size: 14px;
                    text-transform: capitalize;
                    text-align: right;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                }
            }
            .osb-light-booking-summary-service-section {
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 1.63;
                color: #00095b;
                .selected-pickup-date {
                    margin-top: 20px;
                }
                .selected-pickup-time {
                    margin-bottom: 20px;
                }
                .sl-comments {
                    margin: 20px 0 10px 0;
                }
                .customer-address {
                    margin-bottom: 10px;
                }
                .service-title {
                    width: 70%;
                    font-family: $f1-font-bold;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 12px;
                    color: $fm-breadcrumb-blue;
                }
                .services-list {
                    width: 100%;
                    .single-service {
                        display: flex;
                        font-family: $f1-font-regular;
                        .service-name {
                            width: 50%;
                            margin: 10px 10px 0px 0;
                            font-size: 14px;
                            line-height: 20px;
                            color: $fm-text-grey;
                        }

                        .light-recall-service-desc {
                            font-size: 10px;
                            word-break: break-all;
                        }
                        .service-price {
                            width: 50%;
                            padding-top: 10px;
                            margin-bottom: 12px;
                            font-size: 14px;
                            text-align: right;
                            color: $fm-text-grey;

                            .discountedPrice {
                                margin-top: 12px;
                                text-decoration: line-through;
                                color: red;
                            }
                        }
                    }
                }
                .image-upload-count {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #00095b;
                    margin-bottom: 14px;
                }
                .service-change-link {
                    width: 30%;
                    text-align: right;
                    color: $fm-breadcrumb-blue;
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                    text-transform: capitalize;
                }
            }
            .service-location-container {
                .service-location-title {
                    width: 70%;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    line-height: 1.63;
                    color: $fds-color-primary;
                    margin-bottom: 12px;
                }
                .service-location-details {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #00095b;
                    margin-bottom: 10px;
                    .sl-comments {
                        margin: 22px 0 10px 0;
                    }
                }
            }

            .datetime-section {
                .datetime-title {
                    width: 70%;
                    font-family: $f1-font-bold;
                    font-size: 14px;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    margin-bottom: 12px;
                }
                .date-time {
                    width: 100%;
                    margin: 12px 1px 16px 1px;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    line-height: 20px;
                    color: #00095b;
                    .time-style {
                        line-height: 2.5;
                    }
                }
                .change-link {
                    font-size: 14px;
                    text-transform: capitalize;
                    text-align: right;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                }
            }
            .light-review-voucher-title {
                display: flex;
                flex-direction: row;
                font-size: 16px;
                line-height: 1.63;
                color: #4d4d4d;
                text-transform: uppercase;
                .voucher-check-img {
                    margin-right: 10px;
                }
                .voucher-section {
                    display: flex;
                    word-break: break-word;
                    .voucher-code {
                        margin-right: 10px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                    }
                }
            }
            .invalid-voucher {
                font-size: 16px;
                color: #d62d14;
            }
            .light-voucher-des {
                font-size: 12px;
                line-height: 1.75;
                margin-top: 10px;
                color: #4d4d4d;
            }
            .voucher-disclaimer {
                color: $fm-text-grey;
                font-size: 12px;
                line-height: 16px;
                padding: 0 0 30px;
                border-bottom: 1px solid $fds-color-lt-gray-b;
            }

            .campaign-validation-in-summary {
                width: $campaign-width;
                margin: $campaign-margin;
                font-size: $campaign-font-size;
                line-height: $campaign-line-height;
                letter-spacing: $campaign-letter-spacing;
                color: $campaign-color;
                padding-top: 15px;
            }

            .add-voucher-link,
            .light-remove-voucher-link {
                color: $fm-breadcrumb-blue;
                font-size: 14px;
                line-height: 20px;
                text-decoration: underline;
                cursor: pointer;
                padding-top: 10px;
                width: fit-content;
                font-family: $f1-font-regular;
            }
            .total-price-section {
                display: grid;
                grid-template-columns: 48% 48%;
                grid-gap: 4%;
                margin-bottom: 20px;
                margin-top: 30px;
                .total-price-label {
                    font-size: 14px;
                    font-family: $f1-font-bold;
                    font-weight: 700;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                }
                .total-price {
                    font-size: 14px;
                    font-family: $f1-font-bold;
                    font-weight: 700;
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    text-align: right;

                    .discountedPrice {
                        text-decoration: line-through;
                        color: red;
                    }
                }
            }
            .service-sub-title {
                color: $fm-text-grey;
                font-size: 14px;
                line-height: 20px;
            }
            .hr-line-style {
                border-bottom: 1px solid $fds-color-lt-gray-b;
                width: 100%;
                margin: 40px 0;
                &:first-child {
                    margin-top: 20px;
                }
            }
            .change-link {
                width: 30%;
                font-family: $f1-font-regular;
                font-size: 14px;
                text-decoration: underline;
                line-height: 26px;
                color: $fm-breadcrumb-blue;
                cursor: pointer;
                text-align: right;
            }
        }

        .service-change-link {
            text-align: start;
            width: fit-content;
        }
    }
}
.osb-light-journey-booking-summary::-webkit-scrollbar {
    width: 3px;
}

.osb-light-journey-booking-summary::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: #4d4d4d;
}
