@import '../../../../../styles/main.scss';
.vehicle-detail-container {
    width: 100%;
    padding-bottom: 80px;
    border-bottom: 1px solid $fm-text-grey;
    @include mobile {
        width: 100%;
        padding-bottom: 20px;
    }
    .bottom-text {
        @include mobile {
            width: 98%;
        }
        text-align: left;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        color: #4d4d4d;
        margin: 20px 0;
    }

    .vehicle-details-container {
        width: 100%;
        text-align: left;
        @include mobile {
            width: 100%;
            height: auto;
        }
        height: auto;
        background-color: #ffffff;
        .vin-reg-text {
            padding-top: 20px;
            padding-bottom: 30px;
            padding-left: 0;
        }
        .how-to-find-your-vin {
            margin: 2% 0;
            display: inline-block;
            cursor: pointer;
            .fds-chevron-wrap {
                height: 18px;
            }

            div {
                line-height: 18px;
                display: inline-flex;
                text-decoration: underline;
                text-decoration-color: #00095b;
            }
            .how-to-find-your-vin-label {
                margin-right: 15px;
                font-family: $f1-font-regular;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                text-align: right;
                color: $dark-blue;
                text-decoration: underline;
                text-decoration-color: #00095b;
            }
            .combained-shape {
                text-decoration: none;
                color: $dark-blue;
            }
        }

        .vehicle-detail-submit-cta {
            padding: 1% 0;
            @include mobile {
                padding: 5% 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            button {
                @include mobile {
                    width: 100%;
                }
            }
        }
        .mandatory-error-message {
            font-size: 11px;
            color: #d62d14 !important;
            white-space: break-spaces;
            padding-left: 15px;
            padding-bottom: 10px;
        }
        .vehicle-model-container {
            display: grid;
            grid-column-gap: 5%;
            grid-template-columns: 55% 40%;
            @include mobile {
                flex-direction: column;
                display: flex;
            }
            .vehicle-detail-section {
                @include mobile {
                    text-align: center;
                }
            }
            .vehicle-detail-modal {
                text-align: left;
                font-family: $f1-font-medium;
                font-size: 34px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                color: #00095b;
                @include mobile {
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 24px;
                }
            }
            .vehicle-miles {
                font-size: 16px;
                margin-top: 10px;
                font-family: $f1-font-regular;
                color: #00095b;
                @include mobile {
                    text-align: center;
                }
            }
            .vehicle-added-success-container {
                display: flex;
                padding-top: 10px;
                @include mobile {
                    padding-bottom: 10px;
                }
                .vehicle-added-success-icon {
                    width: 16px;
                    height: 16px;
                    object-fit: contain;
                    margin-top: 3px;
                }
                .added-to-my-account {
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 1.75;
                    color: #008200;
                    padding: 0 0 0 8px;
                }
            }
            .vehicle-added-error-container {
                padding-top: 10px;
                @include mobile {
                    padding-bottom: 10px;
                }
                .vehicle-added-error {
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 1.75;
                    color: #d62d14;
                }
            }
            .vehicle-image-container {
                @include tablet {
                    display: flex;
                }
                font-family: $f1-font-regular;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: #4d4d4d;
                .vehicle-detail-image {
                    width: 100%;
                    height: auto;
                    @include mobile {
                        justify-content: center;
                        margin: auto;
                    }
                    .osb-vehicle-image {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 314px;
                        width: 100%;
                        height: 100%;
                        max-height: 170px;
                        @include mobile {
                            max-width: 293px;
                            max-height: 165px;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        span {
            display: inline-block;
        }
        .label-value-container {
            display: flex;
            word-break: break-word;
            text-align: left;
            .vehicle-detail-label {
                width: 48%;
                @include mobile {
                    width: 60%;
                    padding: 0;
                }
                height: 25px;
                padding: 0 15px 0px;
                font-family: $f1-font-regular;
                font-size: 11px;
                line-height: 1.91;
                color: #4d4d4d;
                text-transform: uppercase;
            }
            .vehicle-detail-value {
                width: 52%;
                @include tablet {
                    height: 25%;
                }
                @include mobile {
                    width: 52%;
                }
                margin: 0 14px 0 0px;
                font-family: $f1-font-regular;
                font-size: 11px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.91;
                color: #4d4d4d;
                text-transform: capitalize;
            }
        }
        .line-break {
            width: 95%;
            height: 1px;
            margin: 9px 15px 9px;
            border: dashed 1px #6e6e6e;
            @include mobile {
                margin: 9px 0 9px 0;
                width: 100%;
            }
        }

        .vehicle-detail-your-vehicle {
            text-align: center;
            font-family: $f1-font-regular;
            margin: 40px 0 30px 0;
            @include tablet {
                text-align: left;
                padding-left: 0px;
            }

            .confirmBox {
                font-size: 16px;
                font-family: $f1-font-regular;
                @include tablet {
                    display: block;
                }
                .not-your-vehicle-label {
                    padding-top: 15px;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.63;
                    color: #4d4d4d;
                }
                .change-or-remove-link {
                    color: #09315a;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .blank-space {
                height: 30px;
            }
        }
    }
    .vin-reg-modal-popup {
        width: 100%;
        object-fit: contain;
        padding: 50px 79px 50px 78px;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.3);
        @include mobile {
            padding: 0px;
            top: 0;
            bottom: 0;
            margin: 0;
        }
        .modal-content {
            width: 50%;
            box-shadow: none;
            border-radius: 16px;
            border: 0;
            @media (max-width: 1366px) {
                width: 75%;
            }

            @include mobile {
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                margin: 0;
                border: none;
                border-radius: 0;
            }
            .modal-header {
                margin-bottom: 3rem;
            }
            .modal-close {
                width: 24px;
                height: 24px;
                margin-right: 10px;
                margin-top: 20px;
                @include mobile {
                    width: 28px;
                    height: 28px;
                    margin-right: 8px;
                    margin-top: 22px;
                }
                img {
                    @include tablet-portrait {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
            .modal-footer {
                @include mobile {
                    display: none;
                }
            }
        }
        .vin-info-title {
            width: 100%;
            margin: 10px 0;
            font-family: $f1-font-medium;
            font-size: 24px;
            font-stretch: normal;
            font-style: normal;
            line-height: 32px;
            color: $fds-color-primary;
        }
        .vin-info-body-content {
            .vin-subtitle {
                margin: 5px 0;
                font-family: $f1-font-regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: $fm-text-grey;
                @include mobile {
                    margin: 10px 0;
                }
            }
            .vin-details {
                margin: 20px 0;
            }
            .vin-info-list {
                list-style: none;
                display: flex;
                padding-top: 10px;
                @include mobile {
                    display: block;
                }

                li {
                    padding: 5px 10px;
                    width: 45%;
                    text-align: center;
                    @include mobile {
                        width: 100%;
                        padding: 20px 0px;
                        margin: 0;
                    }

                    img {
                        width: 50px;
                        height: 50px;
                    }

                    p {
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 20px;
                        text-align: center;
                        color: $fm-text-grey;
                    }
                }
            }
        }
        .modal-body {
            @include mobile {
                background-color: #ffffff;
                padding: 0 20px;
            }
            .vin-content,
            .reg-container {
                padding: 0 25px;
                @include mobile {
                    padding: 0;
                }
            }
            .reg-container {
                @include mobile {
                    padding: 20px 0;
                }
            }
        }
        .modal-line {
            width: 95%;
            margin: 40px auto;
            border: solid 0.5px $fds-color-lt-gray-b;
        }
        .reg-container {
            .reg-content {
                text-align: center;
                width: 44%;
                @include mobile {
                    width: 100%;
                    padding: 0;
                }
                img {
                    width: 50px;
                    height: 50px;
                }
                p {
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 20px;
                    text-align: center;
                    color: #4d4d4d;
                }
            }
            .mileage-content {
                text-align: center;
                width: 44%;
                padding-left: 30px;
                img {
                    width: 50px;
                    height: 50px;
                }
                p {
                    margin: 10px 0 0;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 0;
                    text-align: center;
                    color: #a4a4a4;
                }
            }
        }
    }

    .input-field,
    .input-field-error {
        width: 100%;
        height: 40px;
        margin-top: 5px;
        border-radius: 8px;
        border: 1px solid $fds-color-lt-gray-b;
        font-size: 14px;
        line-height: 20px;
        color: $dyf-color-darkgrey;
        &:focus-visible {
            outline: none;
        }
    }
    .error-message {
        color: #d62d14 !important;
        white-space: break-spaces;
        @include mobile {
            padding: 0px !important;
            font-size: 9px;
        }
    }
    .vin-input-container {
        position: relative;
        input {
            &:focus-visible {
                outline: none;
            }
        }
        .vin-reg-info-icon {
            cursor: pointer;
            position: absolute;
            bottom: 4px;
            right: -43px;
            @include mobile {
                right: -43px;
            }
            font-size: 30px;
            color: #00095b;
        }
    }
    .osb-input-field-vin {
        width: 60% !important;
        text-align: left;
        @include mobile {
            padding: 15px 0;
            width: 90% !important;
        }
        @include tablet {
            padding: 20px 0;
        }
        .error-message {
            p {
                font-size: 11px;
                word-break: break-all;
            }
        }
        .osb-error-icon {
            width: 20px;
            height: 20px;
            margin-top: 5px;
            float: right;
        }
        .input-label {
            font-family: $f1-font-regular;
            height: 12px;
            font-size: 11px;
            @include mobile {
                font-size: 10px;
            }

            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            padding: 1.875 rem 0 1.25 rem 0;
        }
    }
    .osb-input-field-mileage {
        width: 60% !important;
        text-align: left;
        padding: 0 0 25px;
        @include mobile {
            padding: 15px 0;
            width: 90% !important;
        }
        .error-message {
            p {
                font-size: 11px;
                word-break: break-all;
            }
        }
        .osb-error-icon {
            width: 20px;
            height: 20px;
            margin-top: 5px;
            float: right;
        }
        .input-label {
            font-family: $f1-font-regular;
            height: 12 px;
            font-size: 12px;
            @include mobile {
                font-size: 10px;
            }

            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            padding: 1.875 rem 0 1.25 rem 0;
        }
    }
    .allocated-space {
        padding-top: 21px;
    }

    .dropdown-container-wrapper {
        padding-top: 10px;
        width: 60%;
        @include mobile {
            width: 90%;
        }
        .osb-dropdown-input {
            width: 100%;
            padding-bottom: 30px;
            .dropdown-button {
                height: 50px;
            }
        }
    }
}

.add-vehicle-popup-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
    z-index: 2005 !important;
    opacity: 1 !important;
}

[data-popup='modal'].add-vehicle-popup-overlay {
    background: transparent;
}

.add-vehicle-popup-content {
    background: rgb(255, 255, 255);
    display: grid;
    border: 1px solid #949699;
    opacity: 6.4;
    width: 816px;
    height: auto;
    padding: 20px 0 0 0;
    object-fit: contain;
    min-height: 25%;
    border-radius: 3px;
    @include mobile {
        width: 80%;
    }
    .modal-vehicle-add {
        .close-button {
            margin-right: 3%;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            line-height: 10px;
            color: #00095b;
            padding-left: 10px;
            font-size: 210%;
            font-family: $f1-font-light;
            float: right;
        }
        .add-vehicle-button {
            justify-content: center;
            text-align: center;
            width: -moz-fit-content;
            width: fit-content;
            margin: 25px auto;
        }
        .vehicle-content-header {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 32px;
            text-align: center;
            margin-top: 10px;
            @include mobile {
                margin-top: 10px;
            }
        }
        .add-vehicle-modal-body {
            color: #00095b;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin: 50px 0 40px 0;
            padding: 0 20px;
            @include mobile {
                margin: 50px 0 30px 0;
            }
        }

        .modal-close {
            width: 24px;
            height: 24px;
            margin: 10px 0 0 0;
            @include mobile {
                width: 28px;
                height: 28px;
            }
            img {
                width: 22px;
                height: 22px;
                @include mobile {
                    width: 12px;
                    height: 12px;
                }
            }
        }

        .modal-footer {
            margin: 0;
            padding: 30px;
            .osb-modal-footer-buttons {
                margin-top: 20px;
            }
        }
        .text-button {
            margin-left: 0 !important;
        }
    }
}

.Add-Vehicle-Popup-content::-webkit-scrollbar {
    width: 5px;
}

.Add-Vehicle-Popup-content::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #4d4d4d;
}
