@import './light-vehicle-finder-ford.scss'; // Default Ford style

.brand-lincoln {
    .vehicle-detail-container {
        .vehicle-detail-accordion {
            .accordion-title {
                font-family: $proxima-nova-regular;
                color: #324047;
                background-color: #e0e0e0 !important;
            }
        }
        .osb-input-field-vin,
        .osb-input-field-mileage {
            .input-label {
                font-family: $proxima-nova-regular;
                color: #324047;
            }
            .error-message {
                color: #b00020 !important;
            }
        }
        .input-field {
            font-family: $proxima-nova-regular;
            color: #324047;
            background-color: #efefef;
            border: none;
            border-bottom: solid 1px #324047;
            &:hover {
                border-bottom: solid 2px #324047;
            }
            &:focus {
                outline: none;
                border-bottom: solid 2px #f26147;
            }
        }
        .input-field-error {
            font-family: $proxima-nova-regular;
            color: #324047;
            background-color: #efefef;
            border: none;
            border-bottom: solid 2px #b00020;
            &:focus {
                outline: none;
            }
        }

        .bottom-text {
            font-family: $proxima-nova-regular;
            color: #324047;
        }
        .vin-input-container {
            .vin-reg-info-icon {
                color: #324047;
            }
        }
        .panel-container {
            .label-value-container {
                .vehicle-detail-label,
                .vehicle-detail-value {
                    font-family: $proxima-nova-regular;
                    color: #324047;
                }
            }
            .vehicle-image-container {
                .vehicle-detail-your-vehicle {
                    .is-this-your-vehicle-label {
                        font-family: $proxima-nova-regular;
                        color: #324047;
                    }
                    .confirmBox {
                        .change-or-remove-link,
                        .not-your-vehicle-label {
                            font-family: $proxima-nova-regular;
                            color: #4f4c43;
                        }
                    }
                }
            }
        }
        .vin-reg-modal-popup {
            .vin-info-title {
                font-family: $proxima-nova-regular;
                color: #4f4c43;
            }
            .vin-info-body-content {
                p {
                    font-family: $proxima-nova-regular;
                    color: #4f4c43;
                }
                .vin-info-list {
                    li {
                        p {
                            font-family: $proxima-nova-regular;
                            color: #4f4c43;
                        }
                    }
                }
            }
        }
    }
    .vehicle-detail-container
        .vehicle-details-container
        .vehicle-model-container
        .vehicle-detail-modal {
        font-family: $proxima-nova-regular;
        color: #324047;
    }
    .vehicle-detail-container
        .vehicle-details-container
        .vehicle-model-container
        .vehicle-miles {
        font-family: $proxima-nova-regular;
        color: #324047;
    }
}
