@import '../../../../styles/main.scss';
.osb-service-location-container {
    background-color: $fds-color-white;
    display: grid;
    grid-template-columns: 65% 33%;
    grid-column-gap: 2%;
    // padding: 40px 35px 0;
    @include tablet-portrait {
        display: block;
        padding: 0;
    }
    .service-location {
        background-color: $scard-light-grey;
        border-radius: 16px;
        margin-bottom: 30px;
        padding: 50px;
        @include tablet-portrait {
            padding: 27px 27px 24px 23px;
        }

        @include mobile {
            background-color: $fds-color-white;
        }

        .osb-secondary-button {
            padding: 0 0 20px 0;
        }
        .service-location-title {
            font-family: $f1-font-light;
            font-size: 34px;
            line-height: 1.29;
            color: #00095b;
            margin-top: 31px;
            @include tablet-portrait {
                font-size: 32px;
                line-height: 40px;
            }
        }
        .service-location-text {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 1.63;
            color: #4d4d4d;
            margin: 24px 0 50px 0;
            p {
                margin-bottom: 5px;
            }
            ul,
            ol {
                list-style-position: outside;
                padding: 0 20px;
                li {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    &::marker {
                        padding: 0 5px;
                        margin: 20px;
                    }
                }
            }
        }
        .pick-up-location-title {
            font-family: $f1-font-light;
            font-size: 32px;
            line-height: 40px;
            color: $fds-color-primary;
            margin-top: 10px;
            @include tablet-portrait {
                font-size: 20px;
                line-height: 1.5;
            }
        }
        .pick-up-location-text {
            font-family: $f1-font-regular;
            font-size: 14px;
            line-height: 20px;
            color: $fm-text-grey;
            margin: 24px 0 35px;
            p {
                margin-bottom: 5px;
            }
            ul,
            ol {
                list-style-position: outside;
                padding: 0 20px;
                li {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    &::marker {
                        padding: 0 5px;
                        margin: 20px;
                    }
                }
            }
        }
        .location-form {
            display: grid;
            grid-template-columns: 100%;
            margin: 0;
        }
        .input-field,
        .input-field-error {
            width: 100% !important;
            @include tablet-portrait {
                width: 100% !important;
            }
        }

        .input-field {
            border: 1px $fds-color-lt-gray-b solid;
            height: 40px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 20px;
        }

        .street-name,
        .house-number,
        .post-code,
        .city {
            font-size: 100%;
            line-height: 1.63;
            color: #4d4d4d;
            float: left;
            width: 393px;
            margin-bottom: 30px;
            @include tablet-portrait {
                width: 100% !important;
                margin-left: 0;
            }

            input,
            label {
                padding-top: 0px;
                @include tablet-portrait {
                    width: 100%;
                }
            }

            input {
                color: #00095b;
            }

            .osb-error-icon {
                float: right;
                width: 20px;
                margin-bottom: 3px;
            }

            .osb-error-message {
                top: -1.8rem;
                color: rgba(214, 45, 20, 1);
                font-size: 0.75rem;
                line-height: 1.313rem;
            }
        }

        .post-code {
            .auto-complete-container {
                width: 393px;
                @include mobile {
                    width: 100%;
                }
                @media only screen and (min-width: 768px) {
                    width: 393px;
                }

                .autoCompleteText {
                    border: 1px solid #b2b2b2;
                    border-radius: 8px;
                    input {
                        width: 87%;
                        color: #00095b;
                        padding: 0 0 0 15px;
                        height: 35px;
                        border-radius: 8px;
                        font-size: 14px;
                    }
                    button {
                        width: 35px;
                        height: 35px;
                        border-radius: 8px;
                    }
                }

                .auto-complete-label-container {
                    .auto-complete-label {
                        margin-bottom: 5px;
                    }
                }

                .osb-error-icon {
                    width: 17px;
                    vertical-align: top;
                }
            }
            .osb-input-text-error {
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 1.75;
                color: #d62d0a;
            }
        }
        .osb-input-error-icon {
            vertical-align: top;
        }

        .parking-type {
            .dropdown-container-wrapper {
                padding-top: 0;
                .label {
                    font-family: $f1-font-regular;
                    font-size: 12px;
                    line-height: 1;
                    color: #4d4d4d;
                    margin-bottom: 5px;
                    position: relative;
                }
                .dropdown {
                    width: 393px;
                    height: 50px;
                    @include tablet-portrait {
                        width: 100%;
                    }
                    .dropdown-button {
                        border: 1px solid #b2b2b2;
                        border-radius: 8px;
                        height: 40px;
                        .dropdown-current-item {
                            font-family: $f1-font-regular;
                            font-size: 14px;
                            line-height: 1.63;
                            color: $fm-text-grey;
                        }
                    }
                    .error-message {
                        color: #d62d0a;
                    }
                }
            }
        }

        .sl-other-options {
            margin-top: 40px;
            .other-options-title {
                font-family: $f1-font-light;
                font-size: 20px;
                line-height: 1.5;
                color: #4d4d4d;
                text-transform: uppercase;
                margin-bottom: 30px;
            }
            .other-option-service-list {
                padding-right: 18px;
                .osb-check-box-spacing {
                    display: flex;
                    justify-content: space-between;
                    @include mobile {
                        flex-direction: column;
                    }
                    .checkbox-content {
                        display: flex;
                        @include mobile {
                            text-align: left;
                        }
                        .service-description {
                            text-align: left;

                            label {
                                vertical-align: top;
                                margin-right: 10px;
                            }
                            .offer-label {
                                background-color: #008200;
                                font-family: $f1-font-regular;
                                font-size: 12px;
                                color: #ffffff;
                                width: fit-content;
                                margin-bottom: 5px;
                                padding: 2px 5px;
                            }
                            .checkbox-label {
                                @media screen and (max-width: 320px) {
                                    font-size: 13px;
                                }
                            }
                            .service-disclaimers {
                                font-family: $f1-font-regular;
                                font-size: 10px;
                                line-height: 1.91;
                                color: #4d4d4d;
                            }
                        }
                    }
                    .price-details {
                        color: #4d4d4d;
                        @include mobile {
                            padding-left: 33px;
                            text-align: left;
                        }
                        @media screen and (max-width: 320px) {
                            font-size: 13px;
                        }
                        .service-checkbox-price-info {
                            display: flex;
                            .price-on-request {
                                margin-top: 5px;
                            }
                        }
                    }
                    .tooltip.info-icon-tooltip {
                        margin-left: 0px;
                        .tooltip__infoIcon {
                            &.dark {
                                -webkit-text-fill-color: #00095b;
                                font-size: 22px;
                                top: -2px;
                                @media (max-width: 1366px) {
                                    top: -5px;
                                }
                            }
                        }
                    }
                    .service-price-section {
                        font-size: 16px;
                        font-family: $f1-font-bold;
                        line-height: 1.63;
                        color: #4d4d4d;
                        text-align: right;
                        @include mobile {
                            text-align: start;
                            margin-left: 9.5%;
                        }
                        p {
                            @include mobile {
                                display: flex;
                            }
                        }

                        .hasDiscount {
                            text-decoration: line-through;
                            color: red;
                        }
                    }
                }
            }
        }

        .sl-continue-button {
            margin: 40px 0 0px 0;
            @include tablet-portrait {
                display: flex;
            }
        }
        .please-note-text,
        .pickup-and-delivery-disclaimer-text {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 1.63;
            color: #4d4d4d;
            margin-top: 50px;
            margin-bottom: 25px;
        }
    }

    .comment-container {
        .comments-section-title {
            margin: 30px 0 20px;
        }
        .comments-body {
            .comments-header {
                margin-top: 25px;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 1.63;
                color: $fm-text-grey;
                @include mobile {
                    margin-top: 30px;
                }
            }
            .comment-input {
                padding-top: 0;
            }
        }
        .comment-input textarea {
            width: 393px;
            height: 83px;
            padding: 10px;
            border: 1px solid $fds-color-lt-gray-b;
            border-radius: 8px;
            @include tablet-portrait {
                width: 100%;
            }
        }

        .comments-remaining-char {
            color: $darkgrey;
        }
    }
}
