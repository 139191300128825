@import './light-retrieve-booking-summary-ford.scss';
.brand-lincoln {
    .light-retrive-booking-component-container {
        font-family: $proxima-nova-regular;

        .light-retrieve-booking-title {
            font-family: $proxima-nova-semi-bold;
            color: $brand-secondary;
        }

        .light-retrieve-amend-booking-button {
            .model-heading {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
            .modal-body {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .secondary {
                border: solid 1px #00095b;
            }
            .modal-content {
                width: fit-content;
                font-family: $proxima-nova-regular;
            }
        }
        .light-retrieve-cancel-button {
            .model-heading {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
            .modal-body {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }
            .secondary {
                border: solid 1px $brand-secondary;
                margin-bottom: 30px;
            }
            .modal-content {
                width: fit-content;
                font-family: $proxima-nova-regular;
            }
        }
        .light-retrieve-logout-button {
            .fds-dot {
                color: $brand-secondary;
            }

            .light-retrive-logout-button-label {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }

            .model-heading {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }

            .modal-body {
                color: $brand-secondary;
                font-family: $proxima-nova-regular;
            }

            .secondary {
                border: solid 1px $brand-secondary;
            }
        }

        .light-retrieve-amend-booking-button,
        .light-retrieve-cancel-button,
        .light-retrieve-logout-button {
            .modal-footer-buttons {
                .color-outline {
                    color: $brand-secondary;
                    &::before {
                        background-color: transparent;
                        border: 1px solid $brand-secondary;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
