@import '../../../../styles/main.scss';
.osb-banner {
    height: 68px;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    &.webview {
        top: 0 !important;
    }
    @include mobile {
        height: 60px;
        top: 70px;
    }
    @include tablet {
        top: 70px;
    }
    display: flex;
    z-index: 999 !important;
    .leftSide {
        margin: auto;
        text-transform: uppercase;
        height: 100%;
        display: table;
        padding-left: 70px;
        @include mobile {
            width: 100%;
            padding-left: 20px;
        }
        @include tablet {
            width: 100%;
        }
        @include desktop {
            width: 100%;
        }
        background-color: $fds-color-primary;
        span {
            display: table-cell;
            vertical-align: middle;
            color: #fff;
            font-family: $f1-font-regular;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
        }
    }
}
