@import '../../../../../styles/main.scss';

.osb-light-retrieve-booking-container {
    width: 100%;
    background-color: #fff;
    padding: 4% 0;
    @include mobile {
        padding: 5%;
    }
    .osb-retrieve-booking-view-title {
        margin-bottom: 35px;
        font-family: $f1-font-light;
        font-size: 40px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        text-align: center;
        color: #00095b;
        text-transform: capitalize;
        @media only screen and (max-width: 1024px) and (min-width: 768px) {
            font-size: 22px;
        }
        @include mobile {
            font-size: 20px;
            text-align: left;
        }
        @media only screen and (max-width: 280px) {
            font-size: 17px;
            text-align: left;
        }
    }
    .retrieve-booking-section {
        width: 35%;
        margin: 0 auto;
        @include mobile {
            width: 100%;
        }
        @include tablet {
            width: 50%;
        }
        @include desktop {
            width: 35%;
        }

        .email-container {
            margin: 25px 0 25px;
        }
        .access-code-container {
            margin: 0 0 30px;
        }
        .forgotten-access-code-link {
            @include mobile {
                width: auto;
            }
            margin: 0 0 30px;
            text-decoration: underline;
            color: $fm-breadcrumb-blue;
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
        }
        .show-retrieve-access-code-msg {
            font-family: $f1-font-regular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            color: #008200;
            margin-bottom: 18px;
        }
        .hide-retrieve-access-code-msg {
            display: none;
        }
        .retrieve-booking-button {
            display: flex;
            justify-content: space-around;
            margin-bottom: 40px;
        }
        .input-textbox-error-message {
            margin-top: 5px;
            font-size: 12px;
            color: #d62d14;
        }
        .input-textbox {
            width: 100%;
            height: 40px;
            margin: 13px 0 0;
            padding: 12px 41px 12px 15px;
            border-radius: 7px;
            border: solid 1px #6e6e6e;
            background-color: #ffffff;
            font-size: 14px;
            color: #00095b;
            font-family: $f1-font-regular;
        }
        .input-textbox-error {
            border: solid 1px #d62d14;
        }
        .input-textbox-label {
            display: flex;
            font-size: 12px;
            color: $fm-text-grey;
            font-family: $f1-font-regular;
            width: auto;
            .osb-error-icon {
                width: 16px;
                height: 16px;
                @include mobile {
                    width: 14px;
                    height: 14px;
                }
            }
        }
    }
}
