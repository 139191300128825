@import '../../../../styles/main.scss';
.osb-card {
    width: 44.5%;
    margin: 4.5% 0 0 3%;
    padding: 31px;
    border-radius: 12px;
    text-align: left;
    background-color: $scard-light-grey;
    @include desktop {
        min-height: 189px;
        height: auto;
    }
    @include tablet {
        min-height: 189px;
        height: auto;
    }
    @include mobile {
        width: 87%;
        padding: 0 0 31px 0;
        border-radius: 0;
        background-color: #fff;
        border-bottom: 1px solid $fds-color-lt-gray-b;
    }
    .osb-card-title {
        width: 100%;
        height: auto;
        font-family: $f1-font-medium;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 32px;
        letter-spacing: 2px;
        color: $fds-color-primary;
        overflow-wrap: break-word;
        @include mobile {
            width: 100%;
        }
    }
    .osb-card-description {
        width: 100%;
        margin: 10px auto 80px 0;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 22px;
        letter-spacing: 0.75px;
        text-align: left;
        color: $fm-text-grey;
        @include mobile {
            width: 95%;
            margin-top: 8px;
        }

        a {
            color: #00095b;
        }
        overflow-wrap: break-word;
    }
    position: relative;
    .osb-card-cta {
        display: flex;
        justify-content: flex-start;
        position: absolute;
        bottom: 35px;
        width: 100%;
        @include mobile {
            position: static;
            width: 100%;
            justify-content: space-around;
        }
        button {
            font-family: $f1-font-bold;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            letter-spacing: 1;
            @include mobile {
                width: 100%;
                font-family: $f1-font-bold;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
            }
        }
    }
}
