@import './osb-card-ford.scss'; // Default Ford Style

.brand-lincoln {
    .osb-card {
        border: 1px solid #e0e0e0;
        border-radius: 0;
        box-shadow: none;
        .osb-card-title {
            font-family: $proxima-nova-semi-bold;
            color: #324047;
        }
        .osb-card-description {
            font-family: $proxima-nova-regular;
            color: #324047;
            margin: 0 0 100px 0;
            a {
                color: #4f4c43;
            }
        }
    }
}
