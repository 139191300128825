@import './service-location-ford.scss';
.brand-lincoln {
    .osb-service-location-container {
        .service-location {
            .service-location-title {
                font-family: $proxima-nova-regular;
                color: #000;
                letter-spacing: 1px;
            }
            .service-location-text {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }
            .pick-up-location-title {
                font-family: $proxima-nova-regular;
                color: #000;
                letter-spacing: 1px;
            }
            .pick-up-location-text {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
            }

            .osb-input-field {
                border: none;
                border-bottom: solid 1px #6e6e6e;
                background-color: #ecedee;
                font-family: $proxima-nova-regular;
                &:hover {
                    border-bottom: solid 2px #324047;
                }
                &:focus {
                    outline: none;
                    border-bottom: solid 2px $brand-border;
                }
            }
            .osb-input-text-label {
                font-family: $proxima-nova-regular;
            }
            .osb-input-field-error {
                font-family: $proxima-nova-regular;
                color: #324047;
                background-color: #efefef;
                border: none;
                border-bottom: solid 2px #b00020;
                &:focus {
                    outline: none;
                }
            }

            .street-name,
            .house-number,
            .post-code,
            .city {
                font-family: $proxima-nova-regular;
                color: $brand-secondary;
                input {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                }
                .osb-input-text-error {
                    font-family: $proxima-nova-regular;
                    color: $brand-light-error;
                    top: -1.8rem;
                    font-size: 0.75rem;
                    letter-spacing: 1px;
                    line-height: 1.313rem;
                }
            }

            .parking-type {
                .dropdown-container-wrapper {
                    font-family: $proxima-nova-regular;
                    .label {
                        font-family: $proxima-nova-regular;
                    }
                    .dropdown .dropdown-button .dropdown-current-item {
                        font-family: $proxima-nova-regular;
                    }
                }
            }
        }
    }
    .comment-container .comments-body .comments-header {
        font-family: $proxima-nova-regular;
        color: $brand-secondary;
    }
}
