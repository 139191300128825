.brand-lincoln {
    .quick-links-grid-wrapper {
        margin: auto 7px 30px 10px;
        .quick-links-header {
            color: $brand-primary;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
        }
        hr {
            margin-bottom: 20px;
            box-shadow: none;
        }

        .quick-links-card-wrapper {
            display: flex;
            height: auto;
            flex-wrap: wrap;
            margin-right: 10px;
            gap: 10px;
        }
    }
    .quick-links-show-all {
        box-shadow: none;
        justify-content: center;
        display: flex;
        padding-top: 20px;
        .show-all-button {
            justify-content: center;
            .plus-circle {
                margin-left: 10px;
            }
        }
    }
}
