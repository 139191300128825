@import './light-retrieve-access-code-ford.scss'; // Default style FORD

.brand-lincoln {
    .retrieve-access-code-container {
        .retrieve-access-code-header {
            color: #000;
            letter-spacing: 2px;
            font-family: $proxima-nova-regular;
        }
        .retrieve-access-code-sub-header {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            font-weight: 700;
        }
        .email-address-link-container {
            .email-address-container {
                .rac-email-address {
                    border: none;
                    border-bottom: solid 1px #6e6e6e;
                    color: $brand-secondary;
                    background-color: #ecedee;
                    font-family: $proxima-nova-regular;
                    &:hover {
                        border-bottom: solid 2px #324047;
                    }
                    &:focus {
                        outline: none;
                        border-bottom: 2px solid $brand-border;
                    }
                }
                .rac-email-address-error {
                    border-bottom: solid 2px #b00020;
                }
                .rac-email-invalid {
                    font-family: $proxima-nova-regular;
                    color: #b00020;
                    &:focus {
                        outline: none;
                    }
                }
                .email-address-label {
                    font-family: $proxima-nova-regular;
                    color: $brand-secondary;
                }
            }
            .remember-access-code-link {
                color: #4f4c43;
                color: $brand-secondary;
                width: 75%;
            }
        }
    }
}
