@import './light-services-flow-ford.scss'; // Default Ford style

.brand-lincoln {
    .light-journey-servicesflow-page {
        .service-card-list-container {
            .service-card-list-title {
                color: #324047;
                font-family: $proxima-nova-regular;
            }
        }
    }
}
