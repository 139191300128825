@import '../../../../../styles/main.scss';
@import '../../campaign-validation/campaign-validation-variables.scss';
.osb-light-review-booking-container {
    // display: grid;
    // grid-template-columns: 40% 40%;
    // grid-template-rows: auto;
    // grid-column-gap: 5%;
    margin-top: 40px;
    .edit-section {
        display: flex;
        margin-bottom: 15px;
    }
    @include mobile {
        margin-top: 0px;
    }

    .light-review-hr-line-style {
        margin: 35px 0 10px 0;
        border-bottom: solid 1px $fds-color-lt-gray-b;
        width: 100%;
        background-color: #000000;

        @include mobile {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .campaign-validation-in-review-booking {
        width: $campaign-width;
        margin: $campaign-margin;
        font-size: $campaign-font-size;
        line-height: $campaign-line-height;
        letter-spacing: $campaign-letter-spacing;
        color: $campaign-color;
        padding-bottom: 10px;
    }
    .light-review-section-title {
        width: 70%;
        padding: 10px;
        font-size: 24px;
        font-family: $f1-font-medium;
        line-height: 24px;
        color: $fds-color-primary;
        font-weight: 500;
        padding-left: 0;
        text-transform: capitalize;
        @include mobile {
            padding: 10px 0px;
            font-family: $f1-font-medium;
            font-size: 24px;
            line-height: 32px;
            font-weight: 500;
        }
    }
    .light-review-section-body {
        padding: 0 10px;
        font-size: 14px;
        font-family: $f1-font-regular;
        line-height: 20px;
        font-weight: 400;
        color: $fm-text-grey;
        padding-left: 0;
        @include mobile {
            padding: 0;
        }
    }
    .light-review-section-services-body {
        padding: 0 10px;
        font-size: 16px;
        line-height: 24px;
        color: #00095b;
        height: auto;
        font-family: $f1-font-regular;
        padding-left: 0;
        padding-right: 0;
        @include mobile {
            padding: 0;
            line-height: 26px;
        }

        .light-service-group {
            display: grid;
            grid-template-columns: 60% 40%;

            .light-service-name {
                grid-row-start: 1;
                grid-column-start: 1;
                font-family: $f1-font-regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: $fm-text-grey;
                word-wrap: break-word;
                .sl-comments {
                    margin-top: 22px;
                }
                .light-recall-service-desc {
                    font-size: 10px;
                    word-break: break-all;
                }

                .pick-up-details {
                    padding-top: 10px;
                    .selected-pickup-date {
                        margin-top: 10px;
                    }
                    .selected-pickup-time {
                        margin-bottom: 20px;
                    }
                    .customer-address {
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: $fm-text-grey;
                        margin-bottom: 10px;
                    }
                }
            }
            .light-service-price {
                grid-row-start: 1;
                grid-column-start: 2;
                display: grid;
                justify-content: right;
                font-family: $f1-font-bold;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                color: $breadcrumb-blue;
                text-align: right;
                .discountedPrice {
                    text-decoration: line-through;
                    color: #eb0a0a;
                }
            }
        }
    }
    .image-upload-count {
        font-family: $f1-font-regular;
        font-size: 16px;
        line-height: 1.63;
        color: #00095b;
        margin: 13px 0 5px 0;
        padding-left: 10px;
        @include tablet-portrait {
            margin: 10px 0 0 0;
            padding-left: 0;
        }
    }
    .light-review-change-link {
        width: 30%;
        text-align: end;
        padding: 10px 10px;
        font-size: 14px;
        font-family: $f1-font-regular;
        line-height: 20px;
        color: $fm-breadcrumb-blue;
        text-decoration: underline;
        padding-right: 0;
        cursor: pointer;
        text-transform: capitalize;

        @include mobile {
            padding: 10px 0px;
        }
    }
    .service-location-container {
        .service-location-title {
            width: 70%;
            font-size: 20px;
            font-family: $f1-font-medium;
            line-height: 1.63;
            color: $fds-color-primary;
            margin: 16px 0 14px 0;
            padding-left: 10px;
            @include mobile {
                padding-left: 0;
            }
        }
        .service-location-details {
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 1.63;
            color: $fm-text-grey;
            padding-left: 10px;
            .sl-comments {
                margin: 22px 0 10px 0;
            }
            @include mobile {
                padding-left: 0;
            }
        }
    }

    .light-review-comments-section {
        grid-row-start: 5;
        grid-column-start: 1;
        grid-column-end: 3;

        .comment-container .comments-body {
            margin-top: 20px;
        }
        .comment-container .comments-body .comments-remaining-char {
            font-size: 12px;
            line-height: 1;
            color: #4d4d4d;
        }

        .comment-container .comment-input textarea {
            width: 31%;
            height: 64px;
            font-size: 16px;
            line-height: 1.63;
            color: $grey;
            border-radius: 8px;
        }
        .comment-empty-error {
            border: solid 1px #d62d14;
            background-color: #ffffff;
            box-shadow: none;
        }
    }
    .light-review-voucher-section {
        margin-top: 10px;
        grid-row-start: 6;
        grid-column-start: 1;
        grid-column-end: 3;

        .light-add-voucher {
            padding-top: 20px;
            font-size: 14px;
            font-family: $f1-font-regular;
            font-weight: 400;
            line-height: 20px;
            color: $fm-breadcrumb-blue;
            margin-top: 10px;
            text-decoration: underline;
            cursor: pointer;

            @include mobile {
                line-height: 26px;
            }
        }

        .light-review-voucher-title {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            line-height: 1.63;
            color: $fm-text-grey;
            text-transform: uppercase;
            padding: 20px 10px;
            font-family: $f1-font-regular;
        }
        .light-voucher-description-section {
            display: grid;
            grid-template-columns: 60% 40%;

            .light-voucher-des {
                grid-row-start: 1;
                grid-column-start: 1;
                font-size: 12px;
                line-height: 1.75;
                padding-left: 10px;
                padding-bottom: 10px;
                color: $fm-text-grey;
                font-family: $f1-font-regular;
            }
            .light-voucher-off {
                grid-row-start: 1;
                grid-column-start: 2;
                font-size: 16px;
                line-height: 1.31;
                text-align: right;
                color: #008200;
                justify-content: right;
            }
        }
        .light-remove-voucher-link {
            font-size: 16px;
            line-height: 1.63;
            font-family: $f1-font-regular;
            color: $fm-breadcrumb-blue;
            text-decoration: underline;
            white-space: nowrap;
            padding: 0 10px;
            cursor: pointer;
            width: fit-content;
        }
    }

    .light-review-total-section {
        margin-top: 10px;
        grid-row-start: 7;
        grid-column-start: 1;
        grid-column-end: 3;

        .voucher-disclaimer {
            color: $fm-text-grey;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            padding: 18px 0 10px;
        }

        .light-total-body {
            display: grid;
            grid-template-columns: 40% 60%;

            .light-total-title {
                grid-row-start: 1;
                grid-column-start: 1;
                padding: 10px 10px;
                font-size: 24px;
                font-family: $f1-font-medium;
                font-weight: 500;
                line-height: 32px;
                color: $breadcrumb-blue;
                display: flex;
                flex-direction: row;
                text-transform: uppercase;

                @include mobile {
                    font-size: 16px;
                    line-height: 26px;
                }

                .light-total-subtitle {
                    margin-top: 7px;
                    margin-left: 3px;
                    font-family: $f1-font-regular;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: $breadcrumb-blue;
                    text-transform: none;

                    @include mobile {
                        margin-top: 0px;
                        font-size: 11px;
                        line-height: 21px;
                    }
                }
            }
            .light-total-price {
                grid-row-start: 1;
                grid-column-start: 2;
                font-size: 24px;
                font-family: $f1-font-medium;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 32px;
                text-align: right;
                color: $breadcrumb-blue;
                justify-content: right;
                margin-top: 7px;
                @include mobile {
                    font-size: 18px;
                }

                .discountedPrice {
                    text-decoration: line-through;
                    color: #eb0a0a;
                }
            }
        }
    }
}

@media only screen and (max-width: 948px) {
    .osb-light-review-booking-container {
        display: grid;
        grid-template-columns: 100%;

        .osb-light-review-dealer-section {
            grid-column-start: 1;

            // div:first-child {
            //     @include mobile {
            //         display: none;
            //     }
            // }
        }

        .light-review-vehicle-section,
        .light-review-service-section,
        .service-location-container,
        .light-review-delivery-section,
        .light-review-delivery-and-location-section,
        .light-review-without-price-section,
        .light-review-dateTime-section,
        .light-review-contact-section {
            grid-column-start: 1;
        }

        .light-review-section-services-body {
            .light-service-group {
                display: grid;
                grid-template-columns: 55% 45%;
            }
        }

        .light-review-comments-section {
            grid-row-start: 10;
            grid-column-start: 1;
            grid-column-end: 1;

            .comment-container .comments-body {
                margin-top: 20px;
                font-family: $f1-font-regular;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                color: $fm-text-grey;
            }
            .comment-container .comments-body .comments-remaining-char {
                font-size: 12px;
                line-height: 1;
                color: $fm-text-grey;
            }

            .comment-container .comment-input textarea {
                width: 100%;
                height: 170px;
                font-size: 16px;
                line-height: 1.63;
                color: $fm-text-grey;
            }
        }
        .light-review-voucher-section {
            margin-top: 10px;
            grid-row-start: 11;
            grid-column-start: 1;
            grid-column-end: 3;

            .light-remove-voucher-link {
                width: fit-content;
            }
        }
        .light-review-total-section {
            margin-top: 10px;
            grid-row-start: 12;
            grid-column-start: 1;
            grid-column-end: 3;

            .light-total-body {
                display: grid;
                grid-template-columns: 50% 50%;

                .light-total-title {
                    display: grid;
                    font-size: 20px;
                    padding-left: 0;
                    .light-total-subtitle {
                        grid-row-start: 2;
                        grid-column-start: 1;
                        font-size: 12px;
                    }
                }
                // .light-total-price {
                //     margin-top: 13px;
                //     font-family: $f1-font-regular;
                //     font-size: 20px;
                //     font-stretch: normal;
                //     font-style: normal;
                //     line-height: 40px;
                //     text-align: right;
                //     color: #00095b;

                //     @include mobile {
                //         font-size: 16px;
                //         line-height: 24px;
                //     }
                // }
            }
        }
    }
}
