@import '../../../../../../styles/main.scss';
.voucher-popup-content {
    background: rgb(255, 255, 255);
    display: grid;
    border: 1px solid #949699;
    opacity: 6.4;
    width: 35%;
    height: auto;
    padding: 30px 10px;
    object-fit: contain;
    min-height: 30%;
    border-radius: 16px;
    .osb-loading {
        .fds-activity-indicator {
            &.full-height {
                height: 100vh;
            }
        }
    }

    .fds-activity-indicator--circular:not(.fds--ie11) {
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        position: relative;
        & .fds-activity-indicator__progress-wrapper {
            height: 81px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            &::before {
                content: '';
                display: block;
                border: 1px solid #4d4d4d;
                border-radius: 50%;
                width: 75px;
                height: 75px;
            }
        }

        & .fds-activity-indicator__progress-line {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        & .fds-activity-indicator__progress-line_svg {
            width: 81px;
            height: 81px;
            transform: rotate(-90deg);
        }

        & .fds-activity-indicator__progress-line_line--indeterminate {
            stroke-dasharray: 38, 162;
            stroke-dashoffset: 0;
            stroke-width: 2px;
            animation: fds-activity-indicator__animation--circular--indeterminate
                3s linear infinite;
            stroke-linecap: round;
            stroke: #00095b;
        }

        @-webkit-keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes fds-activity-indicator__animation--circular--indeterminate {
            0% {
                stroke-dashoffset: 200;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }
    }

    .fds-activity-indicator__notification,
    .fds-activity-indicator__instructions {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .modal-voucher {
        .close-button-voucher {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            position: relative;
            line-height: 21px;
            color: $fm-text-grey;
            padding-left: 10px;
            font-size: 60px;
            font-family: $f1-font-light;
            @media only screen and (max-width: 948px) {
                font-size: 40px;
            }
        }
        .content-header-voucher {
            display: flex;
            justify-content: center;
            font-family: $f1-font-regular;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            color: $fds-color-primary;
            width: 100%;
        }
        .content-voucher-body {
            display: grid;
            grid-template-columns: 70%;
            justify-content: center;
            margin-top: 20px;

            .osb-error-icon {
                width: 20px;
                margin-left: 10px;
            }
            .content-voucher-input {
                grid-column-start: 1;
                grid-row-start: 1;
                width: 100%;
                height: 40px;
                border-radius: 8px;
                border: solid 1px $grey;
                font-family: $f1-font-regular;
                font-size: 14px;
                line-height: 1.63;
                color: $dyf-color-darkgrey;
                padding: 12px 15px;
            }
            .voucher-input-shadow {
                box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                    0 20px 20px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #6e6e6e;
                background-color: #ffffff;
                color: #00095b;
                margin-bottom: 5px 0 24px 0;
            }
            .voucher-input-error {
                border: solid 1px #d62d14;
                background-color: #ffffff;
                box-shadow: none;
            }
            .voucher-invalid {
                width: auto;
                height: auto;
                margin-top: 5px;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 1.75;
                color: #d62d14;
            }
            .content-vouchers-text-description {
                grid-column-start: 1;
                grid-row-start: 2;
                margin-top: 12px;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 1;
                color: $darkgrey;
            }
            .content-add-voucher-button {
                grid-column-start: 1;
                grid-row-start: 3;
                margin-top: 45px;
                display: flex;
                justify-content: center;
            }
        }
        .content-voucher-footer {
            display: grid;
            grid-template-columns: 50%;
            justify-content: center;
            margin-top: 20px;

            .content-voucher-applied-title {
                grid-column-start: 1;
                grid-row-start: 1;
                display: flex;
                flex-direction: row;

                .content-voucher-applied {
                    font-family: $f1-font-regular;
                    margin-left: 15px;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #4d4d4d;
                    text-transform: uppercase;
                }
            }
            .content-voucher-applied-desc {
                grid-column-start: 1;
                grid-row-start: 2;
                height: auto;
                font-family: $f1-font-regular;
                font-size: 12px;
                line-height: 1.75;
                color: #4d4d4d;
                margin-top: 20px;
            }
            .content-voucher-footer-button {
                grid-column-start: 1;
                grid-row-start: 3;
                height: auto;
                display: grid;
                margin-top: 20px;
                grid-template-columns: 50% 50%;

                .content-voucher-remove-link {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #00095b;
                    margin-top: 5%;
                    text-decoration: underline;
                    white-space: break-spaces;
                    cursor: pointer;
                }

                .conntent-voucher-confirm-button {
                    grid-column-start: 2;
                    grid-row-start: 1;
                    justify-content: end;
                    margin-top: 5%;
                }
                .primary-button {
                    box-sizing: border-box;
                    font-size: 14px;
                    line-height: 1.05;
                    text-align: center;
                    color: white;
                    background-color: #00095b;
                    border-radius: 32px;
                    padding: 5px 1.875rem;
                    min-height: 40px;
                    height: auto;
                    display: grid;
                    cursor: pointer;
                    word-wrap: break-word;
                    align-items: center;
                    &::before {
                        background-color: $fm-skyview;
                    }
                    span {
                        white-space: break-spaces;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 948px) {
    .voucher-popup-content {
        opacity: 6.4;
        width: 90%;
        padding: 20px;
        .modal-voucher {
            .content-header-voucher {
                font-size: 28px;
                line-height: 36px;
                text-align: center;
                width: 100%;
                margin-top: 15px;
            }
            .content-voucher-body {
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                .content-voucher-input {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    width: 100%;
                    height: 50px;
                    border-radius: 3px;
                    border: solid 1px #6e6e6e;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #4d4d4d;
                }

                .content-add-voucher-button {
                    grid-column-start: 1;
                    grid-row-start: 3;
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                }
            }
            .content-voucher-footer {
                display: grid;
                grid-template-columns: 100%;
                justify-content: center;
                margin-top: 20px;

                .content-voucher-applied-title {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    display: flex;
                    flex-direction: row;

                    .content-voucher-applied {
                        font-family: $f1-font-regular;
                        margin-left: 15px;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #4d4d4d;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
                .content-voucher-applied-desc {
                    grid-column-start: 1;
                    grid-row-start: 2;
                    height: auto;
                    font-size: 12px;
                    line-height: 1.75;
                    color: #4d4d4d;
                }
                .content-voucher-footer-button {
                    grid-column-start: 1;
                    grid-row-start: 3;
                    height: auto;
                    display: grid;
                    margin-top: 20px;
                    grid-template-columns: 100%;

                    .content-voucher-remove-link {
                        grid-column-start: 1;
                        grid-row-start: 2;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #00095b;
                        margin-top: 5%;
                        text-decoration: underline;
                        white-space: nowrap;
                        display: flex;
                        justify-content: center;
                    }

                    .conntent-voucher-confirm-button {
                        grid-column-start: 1;
                        grid-row-start: 1;
                        display: flex;
                        justify-content: center;
                    }
                    .primary-button {
                        box-sizing: border-box;
                        font-size: 18px;
                        line-height: 1.05;
                        text-align: center;
                        color: white;
                        background-color: #00095b;
                        border-radius: 32px;
                        padding: 0 1.875rem;
                        min-height: 2.5rem;
                        max-height: auto;
                        align-items: center;
                    }
                }
            }
        }
    }
}
.voucher-popup-overlay {
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 2002 !important;
    opacity: 1 !important;
}

[data-popup='tooltip'].voucher-popup-overlay {
    background: transparent;
}
