@import './light-review-booking-ford.scss';

.brand-lincoln {
    .osb-light-review-booking-component-container {
        .light-review-booking-title {
            font-family: $proxima-nova-regular;
            color: $brand-secondary;
            letter-spacing: 2px;
        }
    }
}
