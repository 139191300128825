@import '../../../../../styles/main.scss';

.accordion {
    width: 100%;
}

.accordion-section {
    position: relative;
    color: $dropdown-text;
    font-family: $f1-font-regular;
    width: 100%;
    text-align: center;

    @include tablet {
        width: initial;
        min-width: 17.5rem;
    }

    @include desktop {
        width: 100%;
    }
    .accordion-title {
        padding: 15px 25px;
        box-sizing: border-box;
        text-align: center;
        background-color: $white;
        width: 100%;
        display: flex;
        justify-content: space-between;
        -webkit-appearance: none;
        align-items: center;
        cursor: pointer;
        font-family: $f1-font-light;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        outline: none;
        &:hover {
            cursor: pointer;
        }
        @include mobile {
            margin: 0;
            width: 100%;
        }
        &.border-bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }
    }
    .header-color {
        color: $fds-color-primary;
    }
    .panel {
        display: none;
        &.expanded {
            display: block;
        }
    }

    .osb-mobile-summary-section {
        height: 100px;
        padding: 15px;

        .total-price {
            display: flex;
            flex-direction: row;
            font-family: $f1-font-bold;
            font-size: 14px;
            line-height: 20px;
            color: $fm-text-grey;
            text-align: start;
            margin-bottom: 10px;
            .totalPriceValue {
                margin-left: 5px;
            }

            .discountedPrice {
                text-decoration: line-through;
                color: red;
            }
        }
        .summary-button-section {
            display: flex;
            .view-summary,
            .add-voucher,
            .light-remove-voucher-link {
                padding: 10px 0;
                font-size: 14px;
                text-align: start;
                line-height: 20px;
                color: $fm-breadcrumb-blue;
                text-decoration: underline;
                white-space: break-spaces;
                word-break: break-all;
            }

            span {
                padding: 0 25px;
                text-indent: -9999px;
            }
        }
    }
}

.osb-mobile-booking-summary {
    width: 100%;
    display: flex;
    color: #00095b;
    bottom: 0;
    position: fixed;
    z-index: 2004;
    background-color: #fff;
    border-top: 0.2px solid #a7a7a7;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.1),
        10px 20px 20px 10px rgba(0, 0, 0, 0.1);
    .view-summary-accordion {
        width: 100%;
        .osb-light-journey-mobile-booking-summary {
            .close-icon {
                float: right;
                margin-right: 20px;
                font-size: 30px;
            }

            .osb-review-booking-container {
                text-align: start;
                padding: 0 25px;
                max-height: 500px;
                overflow: auto;
                width: 100%;
                .edit-section {
                    display: flex;
                }
                .vehicle-section {
                    .vehicle-title {
                        width: 70%;
                        margin-bottom: 13px;
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        color: $fds-color-primary;
                    }
                    .vehicle-name {
                        width: 100%;
                        margin: 0 0 16px 1px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        color: #00095b;
                    }
                }
                .dealer-section {
                    .dealer-title {
                        width: 70%;
                        margin-bottom: 13px;
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        color: $fds-color-primary;
                    }
                    .dealer-name {
                        width: 100%;
                        margin: 0 0 4px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        color: #00095b;
                        text-transform: none;
                    }
                    .dealer-address {
                        width: 100%;
                        margin: 4px 80px 0 0;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        color: #00095b;
                    }
                }
                .datetime-section {
                    .date-time {
                        width: 100%;
                        margin: 12px 1px 16px 1px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.5;
                        color: #00095b;
                        text-transform: none;
                        .time-style {
                            line-height: 2.5;
                        }
                    }
                    .datetime-title {
                        width: 70%;
                        margin-bottom: 13px;
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        color: $fds-color-primary;
                    }
                }
                .service-sub-title {
                    color: #00095b;
                    font-size: 16px;
                    line-height: 2.5;
                }
                .osb-light-booking-summary-service-section {
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 1.63;
                    color: #00095b;
                    .selected-pickup-date {
                        margin-top: 10px;
                    }
                    .selected-pickup-time {
                        margin-bottom: 10px;
                    }
                    .sl-comments {
                        margin: 22px 0 10px 0;
                    }
                    .service-title {
                        width: 70%;
                        margin-bottom: 13px;
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        line-height: 1.63;
                        color: $fds-color-primary;
                    }
                    .services-list {
                        width: 100%;
                        .single-service {
                            margin-top: 2px;
                            display: flex;
                            font-family: $f1-font-regular;
                            .service-name {
                                width: 50%;
                                margin: 0 10px 10px 0;
                                font-size: 16px;
                                line-height: 1.63;
                                color: #00095b;
                                word-wrap: break-word;
                            }

                            .light-recall-service-desc {
                                font-size: 10px;
                            }
                            .service-price {
                                width: 50%;
                                padding-top: 10px;
                                margin-bottom: 12px;
                                font-size: 16px;
                                line-height: 0.6;
                                text-align: right;
                                color: $fds-color-primary;
                                white-space: nowrap;

                                .discountedPrice {
                                    margin-top: 12px;
                                    text-decoration: line-through;
                                    color: red;
                                }
                            }
                        }
                    }
                    .image-upload-count {
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #00095b;
                        margin-bottom: 10px;
                    }
                    .service-change-link {
                        width: 30%;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        text-decoration: underline;
                        line-height: 1.63;
                        color: #00095b;
                        cursor: pointer;
                        text-align: right;
                        text-transform: capitalize;
                    }
                }

                .service-location-container {
                    .service-location-title {
                        width: 70%;
                        font-family: $f1-font-medium;
                        font-size: 16px;
                        margin-bottom: 13px;
                        line-height: 1.63;
                        color: $fds-color-primary;
                    }
                    .service-location-details {
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 1.63;
                        color: #00095b;
                        margin-bottom: 10px;
                        .sl-comments {
                            margin: 22px 0 10px 0;
                        }
                    }
                }
                .change-link {
                    width: 30%;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    text-decoration: underline;
                    line-height: 1.63;
                    color: #00095b;
                    cursor: pointer;
                    text-align: end;
                    text-transform: capitalize;
                }
                .hr-line-style {
                    border-bottom: solid 0.5px #6e6e6e;
                    width: 100%;
                    margin: 24px 0px;
                }
                .voucher-title-mobile {
                    display: flex;
                    text-transform: uppercase;
                    .voucher-check-img-mobile {
                        margin-right: 10px;
                    }
                }
                .campaign-validation-in-summary {
                    + .voucher-title-mobile {
                        margin-top: 10px;
                    }
                }
                .invalid-voucher {
                    font-size: 16px;
                    color: #d62d14;
                }
                .voucher-desc-mobile {
                    padding: 10px 10px;
                }
                .voucher-disclaimer {
                    margin-top: 12px;
                    color: #09315a;
                    font-family: $f1-font-regular;
                    font-size: 11px;
                    line-height: 18px;
                    padding: 0 10px;
                }
            }

            .osb-show-video-link {
                padding-bottom: 10px;
                .show-video-container {
                    display: grid;
                    grid-template-columns: 40px auto;
                    .show-video-icon {
                        background: url(../../../../../assets/owners-osb/show-video.png)
                            no-repeat;
                        cursor: pointer;
                        margin-top: 3px;
                    }

                    .show-video-text {
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.63;
                        cursor: pointer;
                        text-decoration: underline;
                        color: $fm-breadcrumb-blue;
                    }
                }
            }
        }
        button.accordion-title {
            height: 70px;
        }
    }
    .view-voucher-accordion {
        width: 50%;
    }
}
